import React, { useState, useContext, useEffect } from "react";
import BaseModal from "../BaseModal";
import { Button } from "../../ui/button";
import StatisticColumn from "./StatisticColumn";
import { GameStatusContext } from "../../../providers/GameStatusProvider";
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';

function StatisticModal({ open, toggleShowRes }) {
  const { setisEndGameModalOpen, setNickName, nickName, setIsUpdated, gameScore} = useContext(GameStatusContext);
  const [isEditing, setIsEditing] = useState(false);
  const { formatMessage } = useIntl();
  const [nicknamecount, setNicknamecount] = useState(nickName || ''); 
  const handleChange = (event) => {
    setNicknamecount(event.target.value);
    Cookies.set('nickName', event.target.value);
  };

  useEffect(()=>{
    if(nickName) setNicknamecount(nickName)
  },[nickName])

  const handleButtonClick = () => {
    if (nicknamecount) {
      setNickName(nicknamecount);
      setIsUpdated(true);
      setIsEditing(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleButtonClick();
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <BaseModal
      title=""
      initiallyOpen={open}
      setIsOpenProps={toggleShowRes}
      showActionButton={false}
      size="md"
    >
      <div className="flex flex-col place-content-center gap-1 relative pb-4">
        <Button variant="cancel" onClick={() => { setisEndGameModalOpen(false) }}>
          <p className="absolute top-[47%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">x</p>
        </Button>
        <p className="text-center font-bold uppercase text-2xl">
          {formatMessage({ id: 'statistics' })}
        </p>
        <div className="px-5 text-center grid gap-2">
          <StatisticColumn />
          <p>{formatMessage({ id: 'statistics_streak_games' })}</p>
          <div>
            <label>
              <div className="flex justify-center items-center gap-2">
                <div className="font-bold">{formatMessage({ id: 'statistics_nick' })}</div>
                {!nickName || isEditing ? (
                  <>
                    <input
                      className="font-normal border-2 border-slate-300 rounded-lg pl-1.5 text-i h-10 w-32"
                      type="text"
                      value={nicknamecount}
                      onChange={handleChange}
                      onKeyDown={handleKeyPress}
                    />
                    <Button variant="primary" onClick={handleButtonClick}>{formatMessage({ id: 'statistics_ok' })}</Button>
                  </>
                ) : (
                  <p onClick={handleEditClick} className="font-normal text-blue-500 underline cursor-pointer">{nicknamecount}</p>
                )}
              </div>
            </label>
          </div>
        </div>
        <div className="flex justify-center gap-3 mt-3">
          <a href="https://x.com/intent/post?text=Playing%20%23Futbal11+%23FutbalConnections+-+%0A%0Ahttps%3A%2F%2Ffutbal11.com%2F" target="_blank" className="h-[24px]">
            <Button variant="submit">{formatMessage({ id: 'statistics_share_on_x' })}</Button>
          </a>
          <a href="https://www.instagram.com/create/story/" target="_blank">
            <Button variant="primary">{formatMessage({ id: 'statistics_share_on_i' })}</Button>
          </a>
        </div>
      </div>
    </BaseModal>
  );
}

export default StatisticModal;
