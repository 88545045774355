{
    "dependencies": {
        "@radix-ui/react-accordion": "^1.1.2",
        "@radix-ui/react-alert-dialog": "^1.0.5",
        "@radix-ui/react-dropdown-menu": "^1.0.0",
        "@radix-ui/react-popover": "^1.0.7",
        "@radix-ui/react-separator": "^1.0.3",
        "@radix-ui/react-slot": "^1.0.2",
        "@radix-ui/react-tabs": "^1.0.4",
        "@radix-ui/react-toast": "^1.1.5",
        "@radix-ui/react-toggle": "^1.0.3",
        "axios": "^1.7.8",
        "class-variance-authority": "^0.7.0",
        "clsx": "^2.0.0",
        "date-fns": "^2.30.0",
        "eslint": "8.49.0",
        "eslint-config-react-app": "7.0.1",
        "eslint-plugin-react": "^7.37.2",
        "formik": "^2.4.6",
        "js-cookie": "^3.0.5",
        "localforage": "^1.10.0",
        "lucide-react": "^0.288.0",
        "match-sorter": "^8.0.0",
        "new-component": "5.0.2",
        "parcel": "2.9.2",
        "prettier": "2.6.2",
        "process": "0.11.10",
        "query-string": "^8.1.0",
        "react": "18.2.0",
        "react-confetti-explosion": "^2.1.2",
        "react-countdown": "^2.3.5",
        "react-dom": "18.2.0",
        "react-intl": "^7.0.1",
        "react-router-dom": "^6.28.0",
        "react-spring": "^9.7.3",
        "rimraf": "5.0.1",
        "sort-by": "^1.2.0",
        "styled-components": "^6.1.0",
        "tailwind-merge": "^1.14.0",
        "tailwindcss-animate": "^1.0.7",
        "ua-parser-js": "^1.0.36",
        "uuid": "^11.0.3",
        "yup": "^1.4.0"
    },
    "scripts": {
        "predev": "rimraf .parcel-cache dist",
        "dev": "parcel src/index.html",
        "build": "parcel build src/index.html",
        "nc": "new-component"
    },
    "staticFiles": {
        "staticPath": "public",
        "staticOutPath": "public"
    },
    "config": {
        "frontendPublicPath": "public"
    },
    "browserslist": "> 0.5%, last 2 versions, not dead",
    "devDependencies": {
        "parcel-reporter-bundle-manifest": "^1.0.0",
        "parcel-reporter-static-files-copy": "^1.5.3",
        "postcss": "^8.4.31",
        "tailwindcss": "^3.3.3"
    }
}
