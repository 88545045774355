import React, { useContext, useEffect, useState } from "react";
import DropDown from './DropDown';
import InfoModal from "../modals/InfoModal";
import { GameStatusContext } from "../../providers/GameStatusProvider";
import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import StatisticModal from "../modals/StatisticModal";
import { getGeo } from "../../lib/geo-helpers";
const CONNECTION_GAMES_META = require('../../lib/games-meta').CONNECTION_GAMES_META[0];
const GEO_DEFAULT_ID = require('../../lib/geo-helpers').GEO_DEFAULT_ID;

function Header() {
    const { setGameGeo } = useContext(PuzzleDataContext);
    const { locale, setLocale, setisEndGameModalOpen, isEndGameModalOpen } = useContext(GameStatusContext);
    const geoKeys = Object.keys(CONNECTION_GAMES_META).filter(el => el !== GEO_DEFAULT_ID); //keys
    const location = useLocation();

    //change languages
    const { formatMessage } = useIntl();
    const toggleLanguage = () => {
        const nextLocale = locale === 'en' ? 'de' : 'en';
        setLocale(nextLocale);
    };

    //navigate
    const navigate = useNavigate();
    const handleNavigate = () => {
        if (location.pathname.includes(getGeo(geoKeys[0]).path)) {
            navigate(`/highscores${getGeo(geoKeys[0]).path}`);
        }
        else navigate('/highscores');
    }

    useEffect(() => {
        if (location.pathname.includes(getGeo(geoKeys[0]).path)) setGameGeo(getGeo(geoKeys[0]));
        else setGameGeo(getGeo(GEO_DEFAULT_ID));
    }, [location]);

    const addDe = () => {
        if (!location.pathname.includes(getGeo(geoKeys[0]).path)) {
            const searchParams = location.search;
            navigate(getGeo(geoKeys[0]).path);
        }
    };

    const removeDe = () => {
        if (location.pathname.includes(getGeo(geoKeys[0]).path)) {
            navigate(`/`);
        }
    };

    const statistic = () => {
        setisEndGameModalOpen(true)
    }

    return (
        <header>
            <div className="header-wrapper bg-teal-700 flex items-center justify-between bg-opacity-80">
                <div className="flex gap-2 md:gap-3 items-center m-2">
                    <div className="sm:hidden h-[24px] z-50">
                        <DropDown/>
                    </div>
                    <a href="https://www.instagram.com/create/story/" target="_blank" className="h-[24px] hidden sm:block">
                        <button className="instagram cursor-pointer"></button>
                    </a>
                    <a href="https://x.com/intent/post?text=Playing%20%23Futbal11+%23FutbalConnections+-+%0A%0Ahttps%3A%2F%2Ffutbal11.com%2F" target="_blank" className="h-[24px] hidden sm:block">
                        <button className="twitter cursor-pointer"></button>
                    </a>
                    <a className="h-[24px]">
                        <button className="highscores cursor-pointer" onClick={handleNavigate}></button>
                    </a>
                    <button className="earth" onClick={() => { removeDe() }}></button>
                    <button className="germany" onClick={() => { addDe() }}></button>
                </div>
                <h1 className="text-center text-3xl sm:text-4xl font-bold italic ">
                    <a className="cursor-pointer"
                        onClick={() => {
                            location.pathname.includes(getGeo(geoKeys[0]).path) ?
                                navigate(getGeo(geoKeys[0]).path) :
                                navigate(`/`);
                        }}>
                        {formatMessage({ id: 'header_title_soccer' })}<span className="text-yellow-400">{formatMessage({ id: 'header_title_expert' })} </span>
                        {location.pathname.includes(getGeo(geoKeys[0]).path) ?
                            <button className="germany"></button>
                            :
                            <button className="earth"></button>
                        }
                    </a>
                </h1>
                <div className="flex gap-2 md:gap-3 items-center m-2">
                    <button className="pl-2" onClick={toggleLanguage}> {locale === 'en' ? 'EN' : 'DE'} </button>
                    <i className="statistics cursor-pointer" onClick={statistic}></i>
                    <InfoModal />
                </div>
            </div>
            <StatisticModal open={isEndGameModalOpen} />
        </header>
    );
}

export default Header;
