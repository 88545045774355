import React from "react";
import { MAX_MISTAKES } from "../../../lib/constants";
import { Info } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/accordion";
import BaseModal from "../BaseModal";
import FeedbackModal from "../FeedbackModal";
import { useIntl } from 'react-intl';

function InfoModal() {
  const { formatMessage } = useIntl();
  return (
    <BaseModal
      title=""
      trigger={<Info/>}
      initiallyOpen={false}
      actionButtonText={`${formatMessage({ id: 'info_btn_got_it' })}`}
    >
      <Tabs defaultValue="how-to-play" className="mb-6">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="how-to-play">{formatMessage({ id: 'info_how_to' })}</TabsTrigger>
          <TabsTrigger value="about-us">{formatMessage({ id: 'info_about_us' })}</TabsTrigger>
        </TabsList>
        <TabsContent value="how-to-play">
          {" "}
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger>{formatMessage({ id: 'info_what_is_the_goal' })}</AccordionTrigger>
              <AccordionContent>
              {formatMessage({ id: 'info_find_groups' })}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>{formatMessage({ id: 'info_how_do' })}</AccordionTrigger>
              <AccordionContent>
              {formatMessage({ id: 'info_select' })}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>{formatMessage({ id: 'info_how_many' })}</AccordionTrigger>
              <AccordionContent>
              {formatMessage({ id: 'info_you_can' })}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-4">
              <AccordionTrigger>{formatMessage({ id: 'info_how_is_my' })}</AccordionTrigger>
              <AccordionContent>
                <ul className="list-disc ml-5">
                  <li>{formatMessage({ id: 'info_if_you_lose' })}</li>
                  <li>{formatMessage({ id: 'info_winning' })}</li>
                  <li>{formatMessage({ id: 'info_this_means_your' })}</li>
                </ul>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </TabsContent>
        <TabsContent value="about-us">
          {" "}
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger>{formatMessage({ id: 'info_who_are' })}</AccordionTrigger>
              <AccordionContent>
              {formatMessage({ id: 'info_this_project' })}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>{formatMessage({ id: 'info_how_can_i' })}</AccordionTrigger>
              <AccordionContent>
                <p className="mb-1">{formatMessage({ id: 'info_if_you' })}</p>
                <ul className="list-disc">
                  <li>
                    <p>{formatMessage({ id: 'info_help' })}</p>
                    <FeedbackModal btn={true}/>
                  </li>
                </ul>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>
              {formatMessage({ id: 'info_any_other' })}
              </AccordionTrigger>
              <AccordionContent>
                <div className="grid grid-flow-row">
                  <p>{formatMessage({ id: 'info_just' })}</p>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </TabsContent>
      </Tabs>
    </BaseModal>
  );
}

export default InfoModal;
