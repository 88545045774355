
export function status(newStats, isWin) {
  newStats = newStats ?? {
    matches: 0,
    wins: 0,
    currentStreak: 0,
    maxStreak: 0,
  }
  newStats.matches++;
  if (isWin) {
    newStats.wins++;
    newStats.currentStreak++;

    if (newStats.currentStreak > newStats.maxStreak) {
      newStats.maxStreak = newStats.currentStreak;
    }
  } else {
    newStats.currentStreak = 0;
  }
  return newStats;
}
