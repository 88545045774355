import React, { useContext, useEffect, useState } from "react";
import * as styles from "./WordButton.module.css";
import { Toggle } from "../ui/toggle";

import { GameStatusContext } from "../../providers/GameStatusProvider";
import { asset } from "../../lib/frontend";

//debug function  
function isDebugMode() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('debug') === '1';
}

function WordButton({ word, image, fullCandidateSize, correctPlayers }) {
    let wordDebug = isDebugMode() ? word.slice(0, -1) : word;
    const { guessCandidate, setGuessCandidate, setPreviouslyGuess } =
        useContext(GameStatusContext);
    const [isSelected, setIsSelected] = useState(
        !!guessCandidate.includes(wordDebug)
    );

    const isCandidateListFull = guessCandidate.length == fullCandidateSize;

    useEffect(() => {
        setIsSelected(!!guessCandidate.includes(wordDebug));
    }, [guessCandidate]);

    function flipSelection() {
        correctPlayers(false);
        setPreviouslyGuess(false);
        if (isSelected) {
            // remove from candidateGuess
            const updatedGuessCandidate = guessCandidate.filter((w) => {
                return w !== wordDebug;
            });
            setGuessCandidate(updatedGuessCandidate);
            // set state to *not* selected
            setIsSelected(false);
        } else {
            // check if the candidate array is full
            if (!isCandidateListFull) {
                // add to candidateGuess array
                setGuessCandidate([...guessCandidate, wordDebug]);
                // set state to *selected*
                setIsSelected(true);
            }
        }
    }

    //const fontSizeByWordLength = 9characters works with 0.6rem

    function getFontSize(word) {

        // Equal font size
        return null;

        const baseLength = 7;
        const wordLength = word.length;
        let fontSize = 1;
        if (wordLength > baseLength) {
            const numExtraChars = wordLength - baseLength;
            fontSize = fontSize - numExtraChars * 0.1;
            fontSize = Math.max(0.5, fontSize);
            return `${fontSize}em`;
        } else {
            return null;
        }
    }
    // word = "washingtonian";
    return (
        <Toggle
            className={`${styles.growShrink}  soft-shadow select-none w-[80px] h-[95px] md:w-[120px] md:h-[135px] `}
            variant="cards"
            pressed={isSelected}
            onClick={flipSelection}
            size="xs"
        >
            <div className="flex flex-col w-full gap-0 md:gap-0.5">
                <div className="relative overflow-hidden mx-1 h-[72px] md:h-[110px] flex items-end justify-center pt-2">
                    <img src={image} className="border-b border-gray-100 border-opacity-30 rounded-lg h-full" />
                </div>
                <p
                    style={{ fontSize: getFontSize(word) }}
                    className="uppercase text-[0.7rem] md:text-sm truncate mx-[2px] md:mx-1 text-center"
                >
                    {word}
                </p>
            </div>
        </Toggle>

        // <Toggle
        //   className={`${styles.growShrink} soft-shadow select-none w-[75px] h-[75px] md:w-[120px] md:h-[120px] `}
        //   variant="outline"
        //   pressed={isSelected}
        //   onClick={flipSelection}
        //   size="xs"
        // >
        //   <div className="flex flex-col w-full">
        //     <div className="relative overflow-hidden rounded-full mx-3 mt-1">
        //       <img src={image} />
        //       <img className="absolute top-0" src={asset('football.png')} />
        //     </div>
        //     <p
        //       style={{ fontSize: getFontSize(word) }}
        //       className="uppercase text-[0.5rem] md:text-xs truncate px-0.5"
        //     >
        //       {word}
        //     </p>
        //   </div>
        // </Toggle>
    );
}

export default WordButton;
