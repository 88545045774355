let LOCALES_DATA;
try {
    LOCALES_DATA = require('../data/locales').LOCALES[0];
} catch (error) {
    LOCALES_DATA = {
        de: {
            locale: 'de',
            messages: {
                header_title_soccer: "Kickoff-",
                header_title_expert: "11",

                //home page
                home_the_game: "DAS SPIEL",
                home_soccer_expert_is_an_exciting: "Soccer Expert ist ein spannendes Spiel von Futbal 11, das dich herausfordert, versteckte Verbindungen zwischen Gruppen von Spielern zu finden. Das Ziel ist es, vier verschiedene Gruppen zu identifizieren, die jeweils vier Spieler enthalten, die etwas gemeinsam haben.",
                home_at_the_start_of_each_round: "Zu Beginn jeder Runde siehst du ein Raster mit 16 verschiedenen Spielern aus der ganzen Welt. Deine Aufgabe ist es, Gruppen von vier Spielern zu identifizieren, die durch ein gemeinsames Merkmal verbunden sind. Diese Verbindung könnte zum Beispiel sein, dass alle vier Spieler für denselben Verein gespielt haben (z. B. alle vier Spieler haben für AC Milan gespielt), oder andere einzigartige Verbindungen, wie das gleiche Herkunftsland, das Gewinnen eines bestimmten Titels oder das Teilen einer ähnlichen Position auf dem Spielfeld.",
                home_to_make_your_selections: "Um deine Auswahl zu treffen, klicke auf jeden Spieler, von dem du glaubst, dass er zur Gruppe gehört, die die Verbindung teilt. Sobald du vier Spieler ausgewählt hast, von denen du denkst, dass sie zusammengehören, drücke 'Abschicken', um zu überprüfen, ob deine Vermutung korrekt ist. Wenn du richtig liegst, wird die Gruppe gesperrt, und du kannst zur Identifizierung der verbleibenden Verbindungen übergehen. Schließe alle vier Gruppen ab, um die Runde zu gewinnen und zur nächsten Herausforderung zu wechseln!",
                home_start_game: "Spiel starten",

                //game page
                game_match: "Ordne 4 Gruppen von 4 Spielern zu",
                game_next_game_starts_shortly: "Das nächste Spiel beginnt in Kürze",
                game_next_game_starts_in: "Das nächste Spiel beginnt in",
                game_hours: " stunden,",
                game_minutes: " minuten",
                game_and: "und ",
                game_seconds: " sekunden",
                game_congratulations_you_won: "Herzlichen Glückwunsch, du hast gewonnen 😁 Komm morgen für ein weiteres Spiel zurück.",
                game_unfortunately_you_lost: "Leider hast du verloren 😔 Komm morgen für ein weiteres Spiel zurück.",
                game_btn_clear: "Zurücksetzen",
                game_btn_submit: "Abschicken",
                game_btn_resolve: "Auflösen",
                game_btn_statistics: "Meine Statistiken",

                //modal confirm
                confirm_you_want_to_give_up: "Bestätigen, dass du aufgeben möchtest",
                confirm_yes: "Ja",
                confirm_no: "Nein",

                //modal feedback
                feedback_btn: "Rückmeldung",
                feedback_contacting: "Kontaktaufnahme mit dem Team.",
                feedback_thank_you: "Danke, dein Feedback wurde aufgezeichnet!",
                feedback_your_opinion: "Deine Meinung ist uns wichtig",
                feedback_do_you_think: "Denkst du, wir können etwas verbessern oder hinzufügen?",
                feedback_from: "von 1 bis 1200 Zeichen",
                feedback_submit: "Abschicken",
                feedback_name: "Name...",
                feedback_email: "E-Mail...",
                feedback_type_here: "Hier eingeben...",
                feedback_max_1200: "Maximal 1200 Zeichen",
                feedback_the_message_field_is_required: "Das Nachrichtenfeld ist erforderlich.",
                feedback_name_is_required: "Name ist erforderlich",
                feedback_max_255: "Maximal 255 Zeichen",
                feedback_the_email_field: "Das E-Mail-Feld muss eine gültige E-Mail-Adresse enthalten.",
                feedback_email_is_required: "E-Mail ist erforderlich",

                //modal statistics
                statistics: "STATISTIKEN",
                statistic_your_score: "Deine Punktzahl:",
                statistics_streak_games: "Streak - Gewonnene Spiele in Folge",
                statistics_nick: "Spitzname",
                statistics_ok: "OK",
                statistics_share_on_x: "Auf X teilen",
                statistics_share_on_i: "Auf Instagram teilen",
                statistics_played: "Gespielt",
                statistics_wins: "Siege",
                statistics_max_streak: "Max Streak",
                statistics_streak: "Streak",

                //modal info
                info_how_to: "Wie man spielt",
                info_about_us: "Über uns",
                info_what_is_the_goal: "Was ist das Ziel?",
                info_find_groups: "Finde Gruppen von Objekten oder Namen, die etwas gemeinsam haben.",
                info_how_do: "Wie spiele ich?",
                info_select: "Wähle die Objekte aus und tippe auf 'Abschicken', um zu überprüfen, ob deine Vermutung zu einer der Antwortkategorien passt.",
                info_how_many: "Wie viele Versuche habe ich?",
                info_you_can: "Du kannst beliebig viele Versuche machen, bevor das Spiel endet, tippe auf 'Auflösen', um aufzugeben und die Antwort zu sehen.",
                info_who_are: "Wer sind wir?",
                info_this_project: "Dieses Projekt wurde vom Futbal 11-Team ins Leben gerufen.",
                info_how_can_i: "Wie kann ich unterstützen?",
                info_if_you: "Wenn du uns unterstützen möchtest, fühl dich frei!",
                info_help: "Hilf uns, ein zukünftiges Puzzle zu erstellen, indem du",
                info_any_other: "Gibt es andere Projekte, die ich anschauen kann?",
                info_just: "Nur ein paar!",
                info_btn_got_it: "Verstanden!",
                info_how_is_my: "Wie wird meine Punktzahl berechnet?",
                info_if_you_lose: "Wenn du ein Spiel verlierst, beträgt deine Punktzahl 0.",
                info_winning: "Wenn du ein Spiel gewinnst, startest du mit 1000 Punkten. Allerdings werden 50 Punkte für jede falsche Antwort abgezogen, bis zu einem Maximum von 500 Punkten.",
                info_this_means_your: "Das bedeutet, dass deine endgültige Punktzahl für ein Spiel zwischen 500 und 1000 Punkten liegen kann.",            

                //previously guess
                previously_guess: "Du hast diese Vermutung bereits gemacht!",

                //correct players
                correct_players: "Du hast 3 korrekte Spieler einer Gruppe gefunden",

                //highscores Page
                highscores: "HOCHSTAND",
                highscores_you_are_at_position: "Du bist auf Position",
                highscores_position: "Position",
                highscores_score: "Punktzahl",
                highscores_name: "Name",
                highscores_back_to_game: "Zurück zum Spiel",
            },
        },
        en: {
            locale: 'en',
            messages: {
                header_title_soccer: "Kickoff-",
                header_title_expert: "11",

                //home page
                home_the_game: "THE GAME",
                home_soccer_expert_is_an_exciting: "Soccer Expert is an exciting game from Futbal 11 that challenges you to find hidden connections between groups of players. The objective is to identify four distinct groups, each containing four players who share something in common.",
                home_at_the_start_of_each_round: "At the start of each round, you’ll see a grid of 16 different players from around the world. Your task is to identify groups of four players that are connected by a shared trait. This connection could be something like having played for the same club (e.g., all four players have played for AC Milan), or other unique links such as being from the same country, having won a specific championship, or sharing a similar position on the field.",
                home_to_make_your_selections: "To make your selections, click on each player you believe is part of the group that shares the connection. Once you've selected four players that you think belong together, hit 'Submit' to check if your guess is correct. If you're right, the group will be locked in, and you can move on to identify the remaining connections. Complete all four groups to win the round and move to the next challenge!",
                home_start_game: "Start Game",

                //game page
                game_match: "Match 4 groups of 4 players",
                game_next_game_starts_shortly: "Next game starts shortly",
                game_next_game_starts_in: "Next game starts in",
                game_hours: " hours,",
                game_minutes: " minutes",
                game_and: "and ",
                game_seconds: " seconds",
                game_congratulations_you_won: "Congratulations, you won 😁 Come back tomorrow for another game.",
                game_unfortunately_you_lost: "Unfortunately, you lost 😔 Come back tomorrow for another game.",
                game_btn_clear: "Clear",
                game_btn_submit: "Submit",
                game_btn_resolve: "Resolve",
                game_btn_statistics: "My Statistics",

                //modal confirm
                confirm_you_want_to_give_up: "Confirm you want to give up",
                confirm_yes: "Yes",
                confirm_no: "No",

                //modal feedback
                feedback_btn: "Feedback",
                feedback_contacting: "contacting the team.",
                feedback_thank_you: "Thank you, feedback was recorded!",
                feedback_your_opinion: "Your opinion is important to us",
                feedback_do_you_think: "Do you think we can improve or add something?",
                feedback_from: "from 1 to 1200 characters",
                feedback_submit: "Submit",
                feedback_name: "Name...",
                feedback_email: "Email...",
                feedback_type_here: "Type here...",
                feedback_max_1200: "Max 1200 characters",
                feedback_the_message_field_is_required: "The message field is required.",
                feedback_name_is_required: "Name is required",
                feedback_max_255: "Max 255 characters",
                feedback_the_email_field: "The email field must be a valid email address.",
                feedback_email_is_required: "Email is required",

                //modal statistics
                statistics: "STATISTICS",
                statistic_your_score: "Your score:",
                statistics_streak_games: "Streak - Games won in a row",
                statistics_nick: "Nick name",
                statistics_ok: "OK",
                statistics_share_on_x: "Share on X",
                statistics_share_on_i: "Share on Instagram",
                statistics_played: "Played",
                statistics_wins: "Wins",
                statistics_max_streak: "Max Streak",
                statistics_streak: "Streak",

                //modal info
                info_how_to: "How To Play",
                info_about_us: "About Us",
                info_what_is_the_goal: "What's The Goal?",
                info_find_groups: "Find groups of items or names that share something in common.",
                info_how_do: "How Do I Play?",
                info_select: "Select the items and tap 'Submit' to check if your guess matches one of the answer categories.",
                info_how_many: "How Many Tries Do I Get?",
                info_you_can: "You can make any number of tries before the game ends, tap 'Resolve' to give up and reveal the answer.",
                info_who_are: "Who Are Y'all?",
                info_this_project: "This project is brought to you by Futbal 11 team.",
                info_how_can_i: "How Can I Support?",
                info_if_you: "If you'd like to support feel free!",
                info_help: "Help us make an upcoming puzzle by",
                info_any_other: "Any Other Projects to Check Out?",
                info_just: "Just a few!",
                info_btn_got_it: "Got It!",
                info_how_is_my: "How is my score calculated?",
                info_if_you_lose: "If you lose a game, your score will be 0.",
                info_winning: "Winning a game starts you with 1000 points. However, 50 points are deducted for each incorrect guess, up to a maximum deduction of 500 points.",
                info_this_means_your: "This means your final score for a game can range between 500 and 1000 points.",

                //previously guess
                previously_guess: "You previously made this guess!",

                //correct players
                correct_players: "You have found 3 correct players of a group",

                //highscores page
                highscores: "HIGHSCORES",
                highscores_you_are_at_position: "You`re at position",
                highscores_position: "Position",
                highscores_score: "Score",
                highscores_name: "Name",
                highscores_back_to_game: "Back to Game",
            },
        }
    };
}

export const LOCALES = LOCALES_DATA;
