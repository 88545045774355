import React from "react";
import { RouterProvider } from "react-router-dom";
import Header from "../Header";
import Game from "../Game";

import { Toaster } from "../ui/toaster";
import PuzzleDataProvider from "../../providers/PuzzleDataProvider";
import GameStatusProvider from "../../providers/GameStatusProvider";

import { route } from '../../route'

function App() {
  return (
    <PuzzleDataProvider>
      <GameStatusProvider>
        <RouterProvider future={{ v7_startTransition: true}} router={route} />
      </GameStatusProvider>
    </PuzzleDataProvider>
  );
}

export default App;