import { service } from "./service";
import { urls } from "../constant/constant";

export const writeFeedbackServices = async (dto) => {
    const {data} = await service.post(urls.feedback, dto);
    return data 
};

export const writeGameResultServices = async (dto) => {
    const {data} = await service.post(urls.gameResult, dto);
    return data 
};
