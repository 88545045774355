import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import GamePage from "../pages/GamePage";
import HighscoresPage  from "../pages/HighscoresPage";
import { Layout } from "../components/layout";

const route = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true, element: <HomePage />
      },
      {
        path: "/*", element: <HomePage />
      },
      {
        path: "/game/*", element: <GamePage />
      },
      {
        path: "/highscores/*", element: <HighscoresPage />
      },
    ]
  }

],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      v7_startTransition: true,
    }
  });

export {
  route,
}