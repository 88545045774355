import React from "react";
import { GameStatusContext } from "../../../providers/GameStatusProvider";
import { useIntl } from 'react-intl';

function StatisticColumn() {
  const {  statistics} = React.useContext(GameStatusContext);
  const { formatMessage } = useIntl();
   const calculateWinPercentage = () => {
    return statistics.matches === 0 ? 0 : ((statistics.wins / statistics.matches) * 100).toFixed();
  };

  return(
  <div className="text-center grid grid-cols-5 gap-1">
    <div>
      <h3 className="text-2xl font-bold rounded-2xl bg-red-600 text-white">{statistics.matches}</h3>
      <p>{formatMessage({ id: 'statistics_played' })}</p>
    </div>
    <div>
      <h3 className="text-2xl font-bold rounded-2xl bg-green-800 text-white">{statistics.wins}</h3>
      <p>{formatMessage({ id: 'statistics_wins' })}</p>
    </div>
    <div>
      <h3 className="text-2xl font-bold rounded-2xl bg-cyan-500 text-white">{calculateWinPercentage()}&#37;</h3>
      <p>&#37;</p>
    </div>
    <div>
      <h3 className="text-2xl font-bold rounded-2xl bg-red-600 text-white">{statistics.currentStreak}</h3>
      <p>{formatMessage({ id: 'statistics_streak' })}</p>
    </div>
    <div>
      <h3 className="text-2xl font-bold rounded-2xl bg-green-800 text-white">{statistics.maxStreak}</h3>
      <p>{formatMessage({ id: 'statistics_max_streak' })}</p>
    </div>
  </div>
  )
}
export default StatisticColumn;