let CONNECTION_GAMES_META_DATA;
try {
    CONNECTION_GAMES_META_DATA = require('../data/games-meta').CONNECTION_GAMES_META;
} catch (error) {
    const sixHoursFromNow = Math.floor(Date.now() / 1000) + (6 * 60 * 60);
    CONNECTION_GAMES_META_DATA = [
        {
            "int": {
                "gameIndex": 20062,
                "nextGameAt": sixHoursFromNow,
                "baseUrl": "https:\\/\\/soccerexpert.seo-day.de"
            },
            "de": {
                "gameIndex": 20063,
                "nextGameAt": sixHoursFromNow,
                "baseUrl": "https:\\/\\/soccerexpert.seo-day.de"
            }
        }
    ]
}

export const CONNECTION_GAMES_META = CONNECTION_GAMES_META_DATA;

