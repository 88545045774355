import React, {useContext, useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {Button} from "../ui/button";
import {asset} from "../../lib/frontend";
import FeedbackModal from "../modals/FeedbackModal";
import { useIntl } from 'react-intl';
import { GameStatusContext } from "../../providers/GameStatusProvider";
import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";

function Home() {
  const { gameGeo } = useContext(PuzzleDataContext);
  const { setSource } = useContext(GameStatusContext);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const location = useLocation();

  useEffect(()=>{
    setSource(null); //set navigation highscore
  },[])

  const handleNavigate = () => {
    if (location.pathname.includes(gameGeo?.path)) navigate(`/game${gameGeo?.path}`);
    else navigate('/game')
  }
  return (
    <div className="mt-6">
      <div className="fixed right-[10px] bottom-[10px]">
        <FeedbackModal />
      </div>
      <div className={`game-wrapper border border-white rounded-xl bg-teal-700 bg-opacity-80 text-base text-white p-4`}>
        <div className="px-1.5">
          <h4 className="text-center text-4xl font-bold italic mb-2 sm:mb-4">{formatMessage({ id: 'home_the_game' })}</h4>
          <div className="flex flex-col sm:flex-row" >
            <div className="sm:w-6/12 pr-2 pb-2 order-2 mt-2 sm:order-1">
              <img src={asset('gameboard.png')} />
            </div>
            <p className="order-1 sm:order-2 sm:w-6/12 text-base text-white text-justify sm:text-left">
            {formatMessage({ id: 'home_soccer_expert_is_an_exciting' })}
            </p>
          </div>
          <div className="flex justify-center my-2 sm:hidden">
            <Button onClick={handleNavigate} variant="submit" size="" className="px-0.5 text-black w-28">{formatMessage({ id: 'home_start_game' })}</Button>
          </div>
          <div className="grid gap-3 text-justify sm:text-left">
            <p>
            {formatMessage({ id: 'home_at_the_start_of_each_round' })}
            </p>
            <p>
            {formatMessage({ id: 'home_to_make_your_selections' })}
            </p>
               {/* <div>
                <p>Select your mode below: </p>
                  {
                    ['Easy', 'Normal', 'Hard'].map((item, id) => (
                      <div className="radio" key={id}>
                        <input
                          id={`${id}-radio`}
                          name="radio"
                          type="radio"
                          className="mr-1"
                          defaultChecked={id === 0}
                        />
                        <label htmlFor={`${id}-radio`}>{item}</label>
                      </div>
                    ))
                  }
              </div> */}
          </div>
          <div className="flex justify-center mt-4">
            <Button onClick={handleNavigate} variant="submit" size="" className="px-0.5 text-black w-28">{formatMessage({ id: 'home_start_game' })}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
