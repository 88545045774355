import React, { useEffect, useState } from "react";
import { puzzleAnswers } from "../../lib/time-utils";

export const PuzzleDataContext = React.createContext();
const CONNECTION_GAMES = require('../../lib/games').CONNECTION_GAMES[0];
const CONNECTION_GAMES_META = require('../../lib/games-meta').CONNECTION_GAMES_META[0];
const HIGHSCORES  = require('../../lib/highscores').HIGHSCORES[0];

function PuzzleDataProvider({ children }) {
  // const [gameData, setGameData] = useState(puzzleAnswers);
  const [gameData, setGameData] = useState(CONNECTION_GAMES.int);
  const [gameMeta, setGameMeta] = useState(CONNECTION_GAMES_META.int);
  const [gameHighscores, setGameHighscores] = useState(HIGHSCORES.int);

  const [gameGeo,setGameGeo] = useState(null);

  const categorySize = gameData[0].words.length;
  const numCategories = gameData.length;
    const setGameLocale = (locale) => {
      setGameData(CONNECTION_GAMES[locale]);
      setGameMeta(CONNECTION_GAMES_META[locale]);
      setGameHighscores(HIGHSCORES[locale]);
    };

    useEffect(() => {
      if(gameGeo){
      setGameLocale(gameGeo.id);
      }
    }, [gameGeo]);

  return (
    <PuzzleDataContext.Provider
      value={{ gameData, numCategories, categorySize, gameMeta, gameHighscores, gameGeo, setGameGeo }}
    >
      {children}
    </PuzzleDataContext.Provider>
  );
}

export default PuzzleDataProvider;
