export const CONNECTION_GAMES = [
    {
        "int": [
            {
                "words": [
                    "Robertson",
                    "Tierney",
                    "McTominay",
                    "McGinn"
                ],
                "wordsImages": [
                    {
                        "title": "Robertson",
                        "image": "https:\/\/kickoff11.com\/media\/players\/Robertson.webp"
                    },
                    {
                        "title": "Tierney",
                        "image": "https:\/\/kickoff11.com\/media\/players\/Tierney.webp"
                    },
                    {
                        "title": "McTominay",
                        "image": "https:\/\/kickoff11.com\/media\/players\/Mc-Tominay.webp"
                    },
                    {
                        "title": "McGinn",
                        "image": "https:\/\/kickoff11.com\/media\/players\/mcginn.png"
                    }
                ],
                "category": "From Scotland",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "0fb6600758c02e972d6c01b6c0583b00e8f249fc"
            },
            {
                "words": [
                    "Lewandowski",
                    "Szczesny",
                    "Fabianski",
                    "Zielinski"
                ],
                "wordsImages": [
                    {
                        "title": "Lewandowski",
                        "image": "https:\/\/kickoff11.com\/media\/players\/lewandowski.webp"
                    },
                    {
                        "title": "Szczesny",
                        "image": "https:\/\/kickoff11.com\/media\/players\/szczesny.webp"
                    },
                    {
                        "title": "Fabianski",
                        "image": "https:\/\/kickoff11.com\/media\/players\/fabianski.png"
                    },
                    {
                        "title": "Zielinski",
                        "image": "https:\/\/kickoff11.com\/media\/players\/zielinski.png"
                    }
                ],
                "category": "From Poland",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "0fb6600758c02e972d6c01b6c0583b00e8f249fc"
            },
            {
                "words": [
                    "Hernandez",
                    "Jimenez",
                    "Alvarez",
                    "Ochoa"
                ],
                "wordsImages": [
                    {
                        "title": "Hernandez",
                        "image": "https:\/\/kickoff11.com\/media\/players\/chicharito.png"
                    },
                    {
                        "title": "Jimenez",
                        "image": "https:\/\/kickoff11.com\/media\/players\/jimenez.webp"
                    },
                    {
                        "title": "Alvarez",
                        "image": "https:\/\/kickoff11.com\/media\/players\/alvarez.webp"
                    },
                    {
                        "title": "Ochoa",
                        "image": "https:\/\/kickoff11.com\/media\/players\/ochoa.png"
                    }
                ],
                "category": "From Mexico",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "0fb6600758c02e972d6c01b6c0583b00e8f249fc"
            },
            {
                "words": [
                    "Keane",
                    "O'Shea",
                    "R.Keane",
                    "Irwin"
                ],
                "wordsImages": [
                    {
                        "title": "Keane",
                        "image": "https:\/\/kickoff11.com\/media\/players\/robbie-keane.png"
                    },
                    {
                        "title": "O'Shea",
                        "image": "https:\/\/kickoff11.com\/media\/players\/oshea.png"
                    },
                    {
                        "title": "R.Keane",
                        "image": "https:\/\/kickoff11.com\/media\/players\/keane.webp"
                    },
                    {
                        "title": "Irwin",
                        "image": "https:\/\/kickoff11.com\/media\/players\/irwin.png"
                    }
                ],
                "category": "From Ireland",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "0fb6600758c02e972d6c01b6c0583b00e8f249fc"
            }
        ],
        "de": [
            {
                "words": [
                    "Hofmann",
                    "Skrzybski",
                    "Gebel",
                    "Kömür"
                ],
                "wordsImages": [
                    {
                        "title": "Hofmann",
                        "image": "https:\/\/kickoff11.com\/media\/players\/7161-1689710421.png"
                    },
                    {
                        "title": "Skrzybski",
                        "image": "https:\/\/kickoff11.com\/media\/players\/93827-1657792592.png"
                    },
                    {
                        "title": "Gebel",
                        "image": "https:\/\/kickoff11.com\/media\/players\/1074526-1723447785.png"
                    },
                    {
                        "title": "Kömür",
                        "image": "https:\/\/kickoff11.com\/media\/players\/777005-1723534018.png"
                    }
                ],
                "category": "Plays as Midfield - Attacking Midfield",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "9e299873a4f43851de983a4efad92162de7f3a7f"
            },
            {
                "words": [
                    "Rönnow",
                    "Neuer",
                    "Hradecky",
                    "Baumann"
                ],
                "wordsImages": [
                    {
                        "title": "Rönnow",
                        "image": "https:\/\/kickoff11.com\/media\/players\/107775-1692707378.png"
                    },
                    {
                        "title": "Neuer",
                        "image": "https:\/\/kickoff11.com\/media\/players\/17259-1702419450.png"
                    },
                    {
                        "title": "Hradecky",
                        "image": "https:\/\/kickoff11.com\/media\/players\/48015-1695367833.png"
                    },
                    {
                        "title": "Baumann",
                        "image": "https:\/\/kickoff11.com\/media\/players\/55089-1657200130.png"
                    }
                ],
                "category": "Plays as Goalkeeper",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "9e299873a4f43851de983a4efad92162de7f3a7f"
            },
            {
                "words": [
                    "Volland",
                    "Kübler",
                    "Sakar",
                    "Trapp"
                ],
                "wordsImages": [
                    {
                        "title": "Volland",
                        "image": "https:\/\/kickoff11.com\/media\/players\/82009-1726351449.png"
                    },
                    {
                        "title": "Kübler",
                        "image": "https:\/\/kickoff11.com\/media\/players\/93604-1658412582.png"
                    },
                    {
                        "title": "Sakar",
                        "image": "https:\/\/kickoff11.com\/media\/players\/default.png"
                    },
                    {
                        "title": "Trapp",
                        "image": "https:\/\/kickoff11.com\/media\/players\/45672-1724772525.png"
                    }
                ],
                "category": "From Germany",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "9e299873a4f43851de983a4efad92162de7f3a7f"
            },
            {
                "words": [
                    "Moerstedt",
                    "Weiper",
                    "Kramaric",
                    "Onisiwo"
                ],
                "wordsImages": [
                    {
                        "title": "Moerstedt",
                        "image": "https:\/\/kickoff11.com\/media\/players\/923595-1723920909.png"
                    },
                    {
                        "title": "Weiper",
                        "image": "https:\/\/kickoff11.com\/media\/players\/796213-1692000176.png"
                    },
                    {
                        "title": "Kramaric",
                        "image": "https:\/\/kickoff11.com\/media\/players\/46580-1657201316.png"
                    },
                    {
                        "title": "Onisiwo",
                        "image": "https:\/\/kickoff11.com\/media\/players\/119234-1722886304.png"
                    }
                ],
                "category": "Plays as Attack - Centre-Forward",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "9e299873a4f43851de983a4efad92162de7f3a7f"
            }
        ]
    }
]