import React, { useContext, useState, useEffect, useRef } from "react";
import { MAX_MISTAKES } from "../../lib/constants";
import { PuzzleDataContext } from "../PuzzleDataProvider";
import {
    loadGameStateFromLocalStorage,
    saveGameStateToLocalStorage,
} from "../../lib/local-storage";
import {
    isGameDataEquivalent,
    isGuessesFromGame,
} from "../../lib/game-helpers";

import { writeGameResultServices } from "../../service/gameServices";
const GEO_DEFAULT_ID = require('../../lib/geo-helpers').GEO_DEFAULT_ID;

export const GameStatusContext = React.createContext();

function GameStatusProvider({ children }) {
    const { gameData, gameMeta, gameGeo } = useContext(PuzzleDataContext);
    const [submittedGuesses, setSubmittedGuesses] = useState([]);
    const [resolveLose, setResolveLose] = useState(false);
    const [resolveWon, setResolveWon] = useState(false);
    const [locale, setLocale] = useState('en');
    const [nickName, setNickName] = useState('');
    const [statistics, setStatistics] = useState({
        matches: 0,
        wins: 0,
        currentStreak: 0,
        maxStreak: 0
    });
    const [loadedState, setLoadedState] = useState(null);
    const [gameIndex, setGameIndex] = useState(null); //index game
    const [responseMessage, setResponseMessage] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [gameScore, setGameScore] = useState(0);
    const [solvedGameData, setSolvedGameData] = useState([]);
    const [source, setSource] = useState(null);

    useEffect(() => {
        if (gameGeo) {
            if(loadGameStateFromLocalStorage("gameStateUser")){
                setNickName(loadGameStateFromLocalStorage("gameStateUser").nickName) //load nickName
            }
            if (gameGeo?.id === GEO_DEFAULT_ID) {
                setLoadedState(loadGameStateFromLocalStorage("gameState"))
            }
            else {
                setLoadedState(loadGameStateFromLocalStorage(`gameState_${gameGeo.id}`))
            };
            setGuessCandidate([]); // deselect cards
        }
    }, [gameGeo]);

    useEffect(() => {
        if (gameGeo) {
            if (loadedState) {
                setResolveLose(loadedState.resolveLose);
                setResolveWon(loadedState.resolveWon);
                setLocale(loadedState.locale);
                setStatistics(loadedState.statistics);
                setGameIndex(loadedState.gameIndex);
                setGameScore(loadedState.gameScore);
            } else {
                setResolveLose(false);
                setResolveWon(false);
            }
            if (!isGameDataEquivalent({ gd1: gameData, gd2: loadedState?.gameData })) {
                setSolvedGameData([]);
            }
            if (Array.isArray(loadedState?.submittedGuesses)) {
                setSubmittedGuesses(loadedState.submittedGuesses);
            }
            if (Array.isArray(loadedState?.solvedGameData)) {
                setSolvedGameData(loadedState.solvedGameData);
            }
        }
    }, [loadedState]);

    const [isGameOver, setIsGameOver] = useState(false);
    const [isGameWon, setIsGameWon] = useState(false);
    const [guessCandidate, setGuessCandidate] = useState([]);
    const [isEndGameModalOpen, setisEndGameModalOpen] = useState(false);
    const [previouslyGuess, setPreviouslyGuess] = useState(false);
    const numMistakesUsed = submittedGuesses.length - solvedGameData.length;
    const [isReloadGame, setisReloadGame] = useState(false);

    useEffect(() => {
        if (solvedGameData.length === gameData.length) {
            setIsGameOver(true);
            setIsGameWon(true);
        } else {
            setIsGameOver(false);
            setIsGameWon(false);
        };
        if (resolveLose) {
            setIsGameOver(true);
            setIsGameWon(false);
        }
        const gameState = { submittedGuesses, solvedGameData, gameData, resolveLose, resolveWon, locale, statistics, gameIndex, gameScore };
        if (gameGeo) {
            const userNick = { nickName };
            saveGameStateToLocalStorage("gameStateUser",userNick);
            if (gameGeo?.id === GEO_DEFAULT_ID) {
                saveGameStateToLocalStorage("gameState", gameState)
            } else {
                saveGameStateToLocalStorage(`gameState_${gameGeo.id}`, gameState)
            };
        }
    }, [submittedGuesses, solvedGameData, resolveLose, resolveWon, locale, nickName, gameIndex, gameGeo]);

    // check index for new game
    useEffect(() => {
        if (gameMeta.gameIndex !== gameIndex && gameIndex !== null) {
            setSolvedGameData([]);
            setSubmittedGuesses([]);
            setResolveLose(false);
            setResolveWon(false);
            setIsGameOver(false);
        }
    }, [gameIndex, resolveLose, resolveWon])

    //post gameResult
    useEffect(() => {
        if (isUpdated) {
            postGameResult();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    const postGameResult = async () => {
        const gameResult = {
            game_index: gameMeta.gameIndex,
            game_set_id: gameData[0].setId,
            game_result: resolveWon ? "win" : !resolveWon && !resolveLose ? null : "lose",
            submitted_guesses: submittedGuesses.length,
            nickname: nickName,
            game_score: gameScore,
            geo: gameGeo.id,
        };
        try {
            await writeGameResultServices(gameResult);
        }
        catch (e) {
            if (e.message) {
                console.log(e.message);
            } else {
                console.log('unknown error');
            }
        }
    }

    return (
        <GameStatusContext.Provider
            value={{
                isGameOver,
                isGameWon,
                numMistakesUsed,
                solvedGameData,
                setSolvedGameData,
                submittedGuesses,
                setSubmittedGuesses,
                guessCandidate,
                setGuessCandidate,
                setIsGameOver,
                setIsGameWon,
                setResolveLose,
                isEndGameModalOpen,
                setisEndGameModalOpen,
                setPreviouslyGuess,
                previouslyGuess,
                resolveLose,
                setResolveWon,
                resolveWon,
                locale,
                setLocale,
                loadedState,
                setStatistics,
                statistics,
                nickName,
                setNickName,
                setGameIndex,
                gameIndex,
                postGameResult,
                responseMessage,
                setResponseMessage,
                setIsUpdated,
                isReloadGame,
                setisReloadGame,
                gameScore,
                setGameScore,
                source,
                setSource,
            }}
        >
            {children}
        </GameStatusContext.Provider>
    );
}

export default GameStatusProvider;
