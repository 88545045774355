import React, { useState, useContext } from "react";

import BaseModal from "../BaseModal";
import { Button , buttonVariants} from "../../ui/button";
import { cn } from "../../../lib/utils";
import { GameStatusContext } from "../../../providers/GameStatusProvider";
import { PuzzleDataContext } from "../../../providers/PuzzleDataProvider";
import { useIntl } from 'react-intl';

function ConfirmModal({toggleShowRes}) {
const { gameMeta } = useContext(PuzzleDataContext);
const [isOpen, setIsOpen] = useState(false);
const {
  setResolveLose,
  setisEndGameModalOpen,
  setGameIndex,
  setIsUpdated,
} = useContext(GameStatusContext);
const { formatMessage } = useIntl();

const toggleModal = () => {
  setIsOpen(prev => !prev)
}
const resolve = ()=>{
  setResolveLose(true);
  setGameIndex(gameMeta.gameIndex);
  setIsUpdated(true);
  setTimeout(()=>{
    setisEndGameModalOpen(true);
  }, 3000);
}

  return (
    <BaseModal
      title=""
      initiallyOpen={isOpen}
      showActionButton={false}
      setIsOpenProps={toggleModal}
      isOpenProps={isOpen}
      size="xs"
      trigger={ <div 
        className={cn(
          buttonVariants({ variant: "resolve",size:"xl"}),'w-full'
        )}>🤨&nbsp;{formatMessage({ id: 'game_btn_resolve' })}</div>}
    >
      <div className="flex flex-col place-content-center gap-4 relative pb-2">
        <Button variant="cancel" onClick={toggleModal}>
        <p className="absolute top-[47%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">x</p>
        </Button>
        <p className="text-center font-[400] mr-5">{formatMessage({ id: 'confirm_you_want_to_give_up' })}</p>
        <div className="flex justify-center gap-5">
        <Button
          variant="cyan"
         onClick={() => {
          resolve()
        }}>{formatMessage({ id: 'confirm_yes' })}</Button>
        <Button
          variant="cyan"
         onClick={toggleModal}>{formatMessage({ id: 'confirm_no' })}</Button>
        </div>
      </div>
    </BaseModal>
  );
}

export default ConfirmModal;
