let CONNECTION_GAMES_DATA;
try {
    CONNECTION_GAMES_DATA = require('../data/games').CONNECTION_GAMES;
} catch (error) {
    CONNECTION_GAMES_DATA = [
        {
            "int": [
                {
                    "words": [
                        "IcardiINT",
                        "Gaitan",
                        "Tagliafico",
                        "Samuel"
                    ],
                    "wordsImages": [
                        {
                            "title": "IcardiINT",
                            "image": "https://soccerexpert.seo-day.de/media/players/900909-1661800800.jpg"
                        },
                        {
                            "title": "Gaitan",
                            "image": "https://soccerexpert.seo-day.de/media/players/900909-1661800800.jpg"
                        },
                        {
                            "title": "Tagliafico",
                            "image": "https://soccerexpert.seo-day.de/media/players/900909-1661800800.jpg"
                        },
                        {
                            "title": "Samuel",
                            "image": "https://soccerexpert.seo-day.de/media/players/900909-1661800800.jpg"
                        }
                    ],
                    "category": "From Argentina",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                },
                {
                    "words": [
                        "Robertson",
                        "Tierney",
                        "McTominay",
                        "McGinn"
                    ],
                    "wordsImages": [
                        {
                            "title": "Robertson",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Tierney",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "McTominay",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "McGinn",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        }
                    ],
                    "category": "From Scotland",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                },
                {
                    "words": [
                        "Xhaka",
                        "Shaqiri",
                        "Kobel",
                        "Burki"
                    ],
                    "wordsImages": [
                        {
                            "title": "Xhaka",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Shaqiri",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Kobel",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Burki",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        }
                    ],
                    "category": "From Switzerland",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                },
                {
                    "words": [
                        "Hakimi",
                        "Mazraoui",
                        "Ziyech",
                        "Diaz"
                    ],
                    "wordsImages": [
                        {
                            "title": "Hakimi",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Mazraoui",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Ziyech",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Diaz",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        }
                    ],
                    "category": "From Morocco",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                }
            ],
            "de": [
                {
                    "words": [
                        "ManeDE",
                        "Koulibaly",
                        "Mendy",
                        "Diallo"
                    ],
                    "wordsImages": [
                        {
                            "title": "ManeDE",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p208722.png"
                        },
                        {
                            "title": "Koulibaly",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/koulibaly.webp"
                        },
                        {
                            "title": "Mendy",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/mendy.png"
                        },
                        {
                            "title": "Diallo",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/diallo.webp"
                        }
                    ],
                    "category": "From Argentina",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                },
                {
                    "words": [
                        "Kuyt",
                        "Seedorf",
                        "Botman",
                        "De Vrij"
                    ],
                    "wordsImages": [
                        {
                            "title": "Kuyt",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/kuyt.png"
                        },
                        {
                            "title": "Seedorf",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/seedorf.png"
                        },
                        {
                            "title": "Botman",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/botman.png"
                        },
                        {
                            "title": "De Vrij",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/devrij.png"
                        }
                    ],
                    "category": "From Scotland",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                },
                {
                    "words": [
                    "Robertson",
                    "Tierney",
                    "McTominay",
                    "McGinn"
                    ],
                    "wordsImages": [
                        {
                            "title": "Robertson",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/Robertson.webp"
                        },
                        {
                            "title": "Tierney",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/Tierney.webp"
                        },
                        {
                            "title": "McTominay",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/Mc-Tominay.webp"
                        },
                        {
                            "title": "McGinn",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/mcginn.png"
                        }
                    ],
                    "category": "From Switzerland",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                },
                {
                    "words": [
                    "Skriniar",
                    "Skrtel",
                    "Lobotka",
                    "Hamsik"
                    ],
                    "wordsImages": [
                        {
                            "title": "Skriniar",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/skriniar.webp"
                        },
                        {
                            "title": "Skrtel",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/skrtel.png"
                        },
                        {
                            "title": "Lobotka",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/lobotka.png"
                        },
                        {
                            "title": "Hamsik",
                            "image": "https:\/\/soccerexpert.seo-day.de\/media\/players\/p171877.png"
                        }
                    ],
                    "category": "From Morocco",
                    "difficulty": 1,
                    "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                    "setId": "449744eba719315c706cb4fb3f7da640683917c4"
                }
            ]
        }
    ];
}

export const CONNECTION_GAMES = CONNECTION_GAMES_DATA;
