import React, { useState, useContext, useEffect } from "react";
import { shuffleGameData } from "../../lib/game-helpers";
import GameGrid from "../GameGrid";

import CorrectPlayers from "../CorrectPlayers";
import PreviouslyGuess from "../PreviouslyGuess";
import { Button } from "../ui/button";

import { Separator } from "../ui/separator";
import ConfettiExplosion from "react-confetti-explosion";

import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";
import { GameStatusContext } from "../../providers/GameStatusProvider";
import GameControlButtonsPanel from "../GameControlButtonsPanel";
import { useIntl } from 'react-intl';
import { status } from "../../lib/statistics";
import FeedbackModal from "../modals/FeedbackModal";
import { useLocation, useNavigate } from 'react-router-dom';

function Game() {
    const { gameData, gameMeta, categorySize, numCategories } = useContext(PuzzleDataContext);
    const { submittedGuesses, solvedGameData, isGameOver, isGameWon,
        isEndGameModalOpen, setisEndGameModalOpen, resolveWon, setResolveWon, previouslyGuess, statistics,
        setStatistics, setGameIndex, setIsUpdated, isReloadGame, gameScore, setGameScore, setSource } =
        useContext(GameStatusContext);
    const [shuffledRows, setShuffledRows] = useState(
        shuffleGameData({ gameData })
    );

    useEffect(() => {
        setShuffledRows(shuffleGameData({ gameData }));
        setSource('game'); //set navigation highscore
    }, [gameData])

    const [gridShake, setGridShake] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [showRes, setShowRes] = useState(false)
    const [showPlayers, setShowPlayers] = useState(false);
    const { formatMessage } = useIntl();
    const location = useLocation();

    function correctPlayers(item) {
        setShowPlayers(item)
    }

    // use effect to update Game Grid after a row has been correctly solved
    useEffect(() => {
        const categoriesToRemoveFromRows = solvedGameData.map(
            (data) => data.category
        );
        const dataLeftForRows = gameData.filter((data) => {
            return !categoriesToRemoveFromRows.includes(data.category);
        });
        if (dataLeftForRows.length > 0) {
            setShuffledRows(shuffleGameData({ gameData: dataLeftForRows }));
        }
    }, [solvedGameData]);

    // Handle End Game!
    useEffect(() => {
        if (!isGameOver) {
            return;
        }
        // extra delay for game won to allow confetti to show
        const modalDelay = isGameWon ? 2000 : 250;
        const delayModalOpen = window.setTimeout(() => {
            //unmount confetti after modal opens
            setShowConfetti(false);
        }, modalDelay);

        let clearStatisticModal;
        if (!resolveWon) setStatistics(status(statistics, isGameWon));

        if (isGameWon) {
            setShowConfetti(true);
            setGameIndex(gameMeta.gameIndex);
            if (!resolveWon) {
                clearStatisticModal = setTimeout(() => {
                    setisEndGameModalOpen(true);
                    setResolveWon(true);
                    setIsUpdated(true);
                }, 3000);
            }
        }

        return () => {
            window.clearTimeout(clearStatisticModal);
            window.clearTimeout(delayModalOpen)
        };
    }, [isGameOver]);

    //timer for next game
    const currentUnixTime = Math.floor(Date.now() / 1000);
    const futureUnixTime = gameMeta.nextGameAt;
    const [remainingTime, setRemainingTime] = useState(futureUnixTime - currentUnixTime);

    useEffect(() => {
        if (remainingTime <= 0 && !(isReloadGame && isEndGameModalOpen)) {
            setTimeout(() => {
                document.location.reload(true);
            }, 5000);
            return;
        }
        const interval = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [remainingTime, isEndGameModalOpen, isReloadGame]);

    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    const toggleShowRes = () => {
        setShowRes(prev => !prev)
    }

    // add score
    useEffect(() => {
        const metaPid = document.querySelector('meta[name="game-user-score"]')?.getAttribute('content');
        if (metaPid && metaPid > gameScore) setGameScore(metaPid);
    }, [gameScore]);

    return (
        <>
            {(isGameOver || isGameWon) && (
                <div className="bg-yellow-400 p-1 text-center">
                    {remainingTime > 0 ? (
                        <p>{`${formatMessage({ id: 'game_next_game_starts_in' })} 
                ${hours ? hours + `${formatMessage({ id: 'game_hours' })}` : ''} 
                ${minutes ? minutes + `${formatMessage({ id: 'game_minutes' })}` : ''} 
                ${seconds ? `${formatMessage({ id: 'game_and' })}` + seconds + `${formatMessage({ id: 'game_seconds' })}` : ''}`}</p>
                    ) : (
                        <p>{formatMessage({ id: 'game_next_game_starts_shortly' })}</p>
                    )}
                </div>
            )}
            <div className="fixed right-[10px] bottom-[10px]">
                <FeedbackModal />
            </div>
            {!(isGameOver && isGameWon || isGameOver && !isGameWon) && (
                <h4 className="text-xl text-center mt-2">
                    {formatMessage({ id: 'game_match' })}
                </h4>
            )}
            {(isGameOver && isGameWon) && (
                <div className="relative h-[55px] mt-2">
                    <p className="text-center text-white text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[700px] max-w-full">{formatMessage({ id: 'game_congratulations_you_won' })}</p>
                </div>
            )}
            {(isGameOver && !isGameWon) && (
                <div className="relative h-[55px] mt-2">
                    <p className="text-center text-white text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[700px] max-w-full">{formatMessage({ id: 'game_unfortunately_you_lost' })}</p>
                </div>
            )}

            <div className={`game-wrapper`}>
                <meta name="game-user-score" content="950"></meta>
                <GameGrid
                    gameRows={shuffledRows}
                    shouldGridShake={gridShake}
                    setShouldGridShake={setGridShake}
                    correctPlayers={correctPlayers}
                />
                <div>
                    <div className="grid place-content-center h-0 ">
                        {showConfetti && isGameOver && (
                            <ConfettiExplosion
                                particleCount={100}
                                force={0.8}
                                duration={2500}
                            />
                        )}
                    </div>
                    <Separator />
                </div>

                {!isGameOver ? (
                    <>
                        {/* <NumberOfMistakesDisplay /> */}
                        <GameControlButtonsPanel
                            toggleShowRes={toggleShowRes}
                            shuffledRows={shuffledRows}
                            setShuffledRows={setShuffledRows}
                            setGridShake={setGridShake}
                            correctPlayers={correctPlayers}
                        />
                    </>
                ) : (
                    <Button variant="submit" className="w-full" children={formatMessage({ id: 'game_btn_statistics' })} onClick={() => { setisEndGameModalOpen(true) }} />
                )}
                {showPlayers && (
                    <CorrectPlayers />
                )}
                {previouslyGuess && (
                    <PreviouslyGuess />
                )}
            </div>
        </>
    );
}

export default Game;
