import React, { useState, useContext, useEffect } from "react";

import BaseModal from "../BaseModal";
import { Button, buttonVariants } from "../../ui/button";
import { cn } from "../../../lib/utils";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { writeFeedbackServices } from "../../../service/gameServices";
import { GameStatusContext } from "../../../providers/GameStatusProvider";
import { useIntl } from 'react-intl';

function FeedbackModal({ btn }) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    responseMessage, 
    setResponseMessage,
  } = useContext(GameStatusContext);
  const toggleModal = () => {
    setIsOpen(prev => !prev)
  }
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object({
    message: Yup.string()
      .max(1200, `${formatMessage({ id: 'feedback_max_1200' })}`)
      .required(`${formatMessage({ id: 'feedback_the_message_field_is_required' })}`),
    name: Yup.string()
      .required(`${formatMessage({ id: 'feedback_name_is_required' })}`)
      .max(255, `${formatMessage({ id: 'feedback_max_255' })}`),
    email: Yup.string()
      .email(`${formatMessage({ id: 'feedback_the_email_field' })}`)
      .required(`${formatMessage({ id: 'feedback_email_is_required' })}`)
      .max(255, `${formatMessage({ id: 'feedback_max_255' })}`),
  });

  const initialValues = {
    message: '',
    name: '',
    email: '',
  };

  // messages success/error
  const handleSubmit = async (dto) => {
    try {
      await writeFeedbackServices(dto)
      setResponseMessage(true);
      setTimeout(()=>{
        setIsOpen(false);
        setResponseMessage(false);
      },2000);
    }
    catch (e) {
      if (e.message) {
        console.log(e.message);
      } else {
        console.log('unknown error')
      }
    }
  }

  // Close modal when ESC is pressed
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen]);

  return (
    <BaseModal
      title=""
      initiallyOpen={isOpen}
      showActionButton={false}
      setIsOpenProps={toggleModal}
      isOpenProps={isOpen}
      size={'xl'}
      trigger={btn ? <a className={`underline font-bold`}>{formatMessage({ id: 'feedback_contacting' })}</a> :
      <div className={cn(buttonVariants({ variant:"feedback", size:"icon"}))}>
        <div className="min-w-min flex items-center">
          <i className="messege"></i>
          <span className="ml-[7px]">{formatMessage({ id: 'feedback_btn' })}</span>
        </div>
      </div>
      }
    >
      {responseMessage ?
      (<div className="h-20 flex justify-center items-center mb-1">
        <p className="text-2xl">{formatMessage({ id: 'feedback_thank_you' })}</p>
      </div>) : (  
      <div className="flex flex-col text-left place-content-center gap-4 relative">
        <Button variant="cancel" onClick={toggleModal}>
          <p className="absolute top-[47%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">x</p>
        </Button>
        <h5 className="text-base text-center md:text-xl font-[600] mr-6">
          {formatMessage({ id: 'feedback_your_opinion' })}
        </h5>
        <div className="p-3">
          <p>
            {formatMessage({ id: 'feedback_do_you_think' })}
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // post feedback 
              handleSubmit(values);
            }}
          >
            {({ touched, errors }) => (
              <Form className="grid gap-2">
                <div>
                  <div className="relative">
                    <Field
                      as="textarea"
                      name="message"
                      className={`border h-28 p-2 pb-4 w-full rounded-lg text-sm font-normal ${touched.message && errors.message ? 'border-red-500' : 'border-[#e5e7eb]'}`}
                      id="message"
                      maxLength="1200"
                      placeholder={formatMessage({ id: 'feedback_type_here' })}
                    />
                    <p className="absolute bottom-1 left-2 text-[#888] text-sm font-normal">{formatMessage({ id: 'feedback_from' })}</p>
                  </div>
                  <ErrorMessage name="message" component="div" className="text-red-500 text-sm"/>
                </div>

                <div>
                  <Field
                    className={`font-normal border h-10 w-full rounded-lg pl-1.5 text-sm ${touched.name && errors.name ? 'border-red-500' : 'border-[#e5e7eb]'}`}
                    type="text"
                    name="name"
                    placeholder={formatMessage({ id: 'feedback_name' })}
                    maxLength="255"
                  />
                  <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
                </div>

                <div>
                  <Field
                    className={`font-normal border h-10 w-full rounded-lg pl-1.5 text-sm ${touched.email && errors.email ? 'border-red-500' : 'border-[#e5e7eb]'}`}
                    type="email"
                    name="email"
                    placeholder={formatMessage({ id: 'feedback_email' })}
                    maxLength="255"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                </div>

                <div className="flex">
                  <Button variant="submit" type="submit">{formatMessage({ id: 'feedback_submit' })}</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div> 
      )}

    </BaseModal>
  );
}

export default FeedbackModal;
