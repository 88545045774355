let HIGHSCORES_DATA;
try {
    HIGHSCORES_DATA = require('../data/highscores').HIGHSCORES;
} catch (error) {
    HIGHSCORES_DATA = [
        {
            "int": [
                {
                    "position": 1,
                    "score": 1850,
                    "nickname": "Jack",
                    "game_user_pid": "674ec37b01b2c"
                },
                {
                    "position": 2,
                    "score": 1800,
                    "nickname": "Susanna",
                    "game_user_pid": "674ec37b01b2c"
                },
                {
                    "position": 3,
                    "score": 1750,
                    "nickname": "Wilhelmine",
                    "game_user_pid": "674ee61a0c8c7"
                },
                {
                    "position": 4,
                    "score": 1000,
                    "nickname": "Elfi",
                    "game_user_pid": "674ee61a0c926"
                },
                {
                    "position": 5,
                    "score": 1000,
                    "nickname": "Inka",
                    "game_user_pid": "674ee61a0caf6"
                },
                {
                    "position": 6,
                    "score": 1000,
                    "nickname": "Ivan",
                    "game_user_pid": "674ee61a0c934"
                },
                {
                    "position": 7,
                    "score": 1000,
                    "nickname": "Thekla",
                    "game_user_pid": "674ee61a0cb93"
                },
                {
                    "position": 8,
                    "score": 1000,
                    "nickname": "Ramazan",
                    "game_user_pid": "674eebde16a81"
                },
                {
                    "position": 9,
                    "score": 1000,
                    "nickname": "Hanne",
                    "game_user_pid": "674ee61a0cc4d"
                },
                {
                    "position": 10,
                    "score": 1000,
                    "nickname": "Philipp",
                    "game_user_pid": "674ee61a0cb9d"
                },
                {
                    "position": 11,
                    "score": 1000,
                    "nickname": "Gundula",
                    "game_user_pid": "674ee61a0c8d5"
                },
                {
                    "position": 12,
                    "score": 1000,
                    "nickname": "Helmut",
                    "game_user_pid": "674eebde168bf"
                },
                {
                    "position": 13,
                    "score": 1000,
                    "nickname": "Ottilie",
                    "game_user_pid": "674ee61a0ca50"
                },
                {
                    "position": 14,
                    "score": 1000,
                    "nickname": "Norbert",
                    "game_user_pid": "674ee61a0cc88"
                },
                {
                    "position": 15,
                    "score": 1000,
                    "nickname": "Salvatore",
                    "game_user_pid": "674ee61a0c8fe"
                },
                {
                    "position": 16,
                    "score": 1000,
                    "nickname": "Leo",
                    "game_user_pid": "674ee61a0c966"
                },
                {
                    "position": 17,
                    "score": 1000,
                    "nickname": "Peggy",
                    "game_user_pid": "674ee61a0cbba"
                },
                {
                    "position": 18,
                    "score": 1000,
                    "nickname": "Martha",
                    "game_user_pid": "674eebde16ad0"
                },
                {
                    "position": 19,
                    "score": 1000,
                    "nickname": "August",
                    "game_user_pid": "674ee61a0c98d"
                },
                {
                    "position": 20,
                    "score": 1000,
                    "nickname": "Egbert",
                    "game_user_pid": "674ee61a0ca01"
                },
                {
                    "position": 21,
                    "score": 1000,
                    "nickname": "Fritz",
                    "game_user_pid": "674eebde16aff"
                },
                {
                    "position": 22,
                    "score": 950,
                    "nickname": "Babette",
                    "game_user_pid": "674ee61a0ca16"
                },
                {
                    "position": 23,
                    "score": 950,
                    "nickname": "Axel",
                    "game_user_pid": "674eebde168fc"
                },
                {
                    "position": 24,
                    "score": 950,
                    "nickname": "Marcel",
                    "game_user_pid": "674eebde16a64"
                },
                {
                    "position": 25,
                    "score": 950,
                    "nickname": "Wenzel",
                    "game_user_pid": "674ee61a0cbe0"
                },
                {
                    "position": 26,
                    "score": 950,
                    "nickname": "Henny",
                    "game_user_pid": "674eebde16a78"
                },
                {
                    "position": 27,
                    "score": 950,
                    "nickname": "Hugo",
                    "game_user_pid": "674eebde1676a"
                },
                {
                    "position": 28,
                    "score": 950,
                    "nickname": "Mehmet",
                    "game_user_pid": "674eebde168a2"
                },
                {
                    "position": 29,
                    "score": 950,
                    "nickname": "Thilo",
                    "game_user_pid": "674ee61a0ca47"
                },
                {
                    "position": 30,
                    "score": 950,
                    "nickname": "Mark",
                    "game_user_pid": "674ee61a0cb1d"
                },
                {
                    "position": 31,
                    "score": 950,
                    "nickname": "Detlev",
                    "game_user_pid": "674eebde16a95"
                },
                {
                    "position": 32,
                    "score": 950,
                    "nickname": "Leni",
                    "game_user_pid": "674eebde1683f"
                },
                {
                    "position": 33,
                    "score": 950,
                    "nickname": "Eugen",
                    "game_user_pid": "674eebde16a1e"
                },
                {
                    "position": 34,
                    "score": 950,
                    "nickname": "Ulrike",
                    "game_user_pid": "674ee61a0cc13"
                },
                {
                    "position": 35,
                    "score": 950,
                    "nickname": "Christian",
                    "game_user_pid": "674eebde168d3"
                },
                {
                    "position": 36,
                    "score": 950,
                    "nickname": "Melitta",
                    "game_user_pid": "674eebde1698b"
                },
                {
                    "position": 37,
                    "score": 950,
                    "nickname": "Sandy",
                    "game_user_pid": "674ee61a0c970"
                },
                {
                    "position": 38,
                    "score": 950,
                    "nickname": "Ahmet",
                    "game_user_pid": "674eebde1685d"
                },
                {
                    "position": 39,
                    "score": 950,
                    "nickname": "Klaus Peter",
                    "game_user_pid": "674ee61a0cc30"
                },
                {
                    "position": 40,
                    "score": 900,
                    "nickname": "Sylvia",
                    "game_user_pid": "674ee61a0c91c"
                },
                {
                    "position": 41,
                    "score": 900,
                    "nickname": "Ortwin",
                    "game_user_pid": "674ee61a0cb61"
                },
                {
                    "position": 42,
                    "score": 900,
                    "nickname": "Manuel",
                    "game_user_pid": "674ee61a0cb74"
                },
                {
                    "position": 43,
                    "score": 900,
                    "nickname": "Johannes",
                    "game_user_pid": "674eebde16755"
                },
                {
                    "position": 44,
                    "score": 900,
                    "nickname": "Hans",
                    "game_user_pid": "674eebde1682b"
                },
                {
                    "position": 45,
                    "score": 900,
                    "nickname": "Edmund",
                    "game_user_pid": "674eebde16884"
                },
                {
                    "position": 46,
                    "score": 900,
                    "nickname": "Peer",
                    "game_user_pid": "674ee61a0c89a"
                },
                {
                    "position": 47,
                    "score": 900,
                    "nickname": "Ulla",
                    "game_user_pid": "674ee61a0c93e"
                },
                {
                    "position": 48,
                    "score": 900,
                    "nickname": "Dorothea",
                    "game_user_pid": "674eebde16963"
                },
                {
                    "position": 49,
                    "score": 900,
                    "nickname": "Margarete",
                    "game_user_pid": "674ee61a0c9e4"
                },
                {
                    "position": 50,
                    "score": 900,
                    "nickname": "Heinz",
                    "game_user_pid": "674ee61a0cb27"
                },
                {
                    "position": 51,
                    "score": 900,
                    "nickname": "Karlheinz",
                    "game_user_pid": "674ee61a0cba6"
                },
                {
                    "position": 52,
                    "score": 900,
                    "nickname": "Wiebke",
                    "game_user_pid": "674ee61a0c9ed"
                },
                {
                    "position": 53,
                    "score": 900,
                    "nickname": "Mechthild",
                    "game_user_pid": "674ee61a0cc92"
                },
                {
                    "position": 54,
                    "score": 900,
                    "nickname": "Wladimir",
                    "game_user_pid": "674ee61a0cb44"
                },
                {
                    "position": 55,
                    "score": 900,
                    "nickname": "Jennifer",
                    "game_user_pid": "674ee61a0cbc3"
                },
                {
                    "position": 56,
                    "score": 900,
                    "nickname": "Neo22",
                    "game_user_pid": "674ec37b01b2c"
                },
                {
                    "position": 57,
                    "score": 900,
                    "nickname": "Friedrich-Wilhelm",
                    "game_user_pid": "674ee61a0c912"
                },
                {
                    "position": 58,
                    "score": 900,
                    "nickname": "Benedikt",
                    "game_user_pid": "674eebde168f3"
                },
                {
                    "position": 59,
                    "score": 900,
                    "nickname": "Adrian",
                    "game_user_pid": "674eebde16a5a"
                },
                {
                    "position": 60,
                    "score": 850,
                    "nickname": "Heinz-Georg",
                    "game_user_pid": "674eebde16741"
                },
                {
                    "position": 61,
                    "score": 850,
                    "nickname": "Wera",
                    "game_user_pid": "674ee61a0c9aa"
                },
                {
                    "position": 62,
                    "score": 850,
                    "nickname": "Klemens",
                    "game_user_pid": "674eebde169b2"
                },
                {
                    "position": 63,
                    "score": 850,
                    "nickname": "Isolde",
                    "game_user_pid": "674ee61a0ca3d"
                },
                {
                    "position": 64,
                    "score": 850,
                    "nickname": "Olga",
                    "game_user_pid": "674eebde16836"
                },
                {
                    "position": 65,
                    "score": 850,
                    "nickname": "Helen",
                    "game_user_pid": "674ee61a0c9da"
                },
                {
                    "position": 66,
                    "score": 850,
                    "nickname": "Hans-Günther",
                    "game_user_pid": "674ee61a0c908"
                },
                {
                    "position": 67,
                    "score": 850,
                    "nickname": "Ina",
                    "game_user_pid": "674eebde167d1"
                },
                {
                    "position": 68,
                    "score": 850,
                    "nickname": "Sergej",
                    "game_user_pid": "674eebde168df"
                },
                {
                    "position": 69,
                    "score": 850,
                    "nickname": "Beate",
                    "game_user_pid": "674ee61a0caa9"
                },
                {
                    "position": 70,
                    "score": 850,
                    "nickname": "Helma",
                    "game_user_pid": "674eebde16a33"
                },
                {
                    "position": 71,
                    "score": 800,
                    "nickname": "Alfons",
                    "game_user_pid": "674eebde1680e"
                },
                {
                    "position": 72,
                    "score": 800,
                    "nickname": "Harro",
                    "game_user_pid": "674eebde16867"
                },
                {
                    "position": 73,
                    "score": 800,
                    "nickname": "Eugenie",
                    "game_user_pid": "674ee61a0cc3a"
                },
                {
                    "position": 74,
                    "score": 800,
                    "nickname": "Heribert",
                    "game_user_pid": "674eebde1694b"
                },
                {
                    "position": 75,
                    "score": 800,
                    "nickname": "Mathilde",
                    "game_user_pid": "674ee61a0cbfe"
                },
                {
                    "position": 76,
                    "score": 800,
                    "nickname": "Hans-Wilhelm",
                    "game_user_pid": "674ee61a0cc09"
                },
                {
                    "position": 77,
                    "score": 800,
                    "nickname": "Rudi",
                    "game_user_pid": "674ee61a0cc7f"
                },
                {
                    "position": 78,
                    "score": 800,
                    "nickname": "Hans-Werner",
                    "game_user_pid": "674ee61a0c952"
                },
                {
                    "position": 79,
                    "score": 800,
                    "nickname": "Falko",
                    "game_user_pid": "674eebde16aa9"
                },
                {
                    "position": 80,
                    "score": 800,
                    "nickname": "Monica",
                    "game_user_pid": "674eebde16849"
                },
                {
                    "position": 81,
                    "score": 800,
                    "nickname": "Rafael",
                    "game_user_pid": "674eebde16a29"
                },
                {
                    "position": 82,
                    "score": 800,
                    "nickname": "Meinolf",
                    "game_user_pid": "674ee61a0c9f7"
                },
                {
                    "position": 83,
                    "score": 800,
                    "nickname": "Margrit",
                    "game_user_pid": "674eebde16995"
                },
                {
                    "position": 84,
                    "score": 800,
                    "nickname": "Siegmund",
                    "game_user_pid": "674ee61a0cc27"
                },
                {
                    "position": 85,
                    "score": 800,
                    "nickname": "Emanuel",
                    "game_user_pid": "674ee61a0cacf"
                },
                {
                    "position": 86,
                    "score": 800,
                    "nickname": "Friedrich-Wilhelm",
                    "game_user_pid": "674eebde1699f"
                },
                {
                    "position": 87,
                    "score": 800,
                    "nickname": "Artur",
                    "game_user_pid": "674ee61a0cbcd"
                },
                {
                    "position": 88,
                    "score": 750,
                    "nickname": "Susanne",
                    "game_user_pid": "674ee61a0caed"
                },
                {
                    "position": 89,
                    "score": 750,
                    "nickname": "Alwine",
                    "game_user_pid": "674eebde1674b"
                },
                {
                    "position": 90,
                    "score": 750,
                    "nickname": "Hans-Peter",
                    "game_user_pid": "674eebde16822"
                },
                {
                    "position": 91,
                    "score": 750,
                    "nickname": "Traudel",
                    "game_user_pid": "674ee61a0c9b3"
                },
                {
                    "position": 92,
                    "score": 750,
                    "nickname": "Hanno",
                    "game_user_pid": "674eebde16871"
                },
                {
                    "position": 93,
                    "score": 750,
                    "nickname": "Gerhard",
                    "game_user_pid": "674ee61a0ca2a"
                },
                {
                    "position": 94,
                    "score": 750,
                    "nickname": "Margret",
                    "game_user_pid": "674ee61a0cc44"
                },
                {
                    "position": 95,
                    "score": 750,
                    "nickname": "Veronika",
                    "game_user_pid": "674eebde16a14"
                },
                {
                    "position": 96,
                    "score": 750,
                    "nickname": "Rene",
                    "game_user_pid": "674eebde167c7"
                },
                {
                    "position": 97,
                    "score": 750,
                    "nickname": "Valeri",
                    "game_user_pid": "674ee61a0cc1d"
                },
                {
                    "position": 98,
                    "score": 750,
                    "nickname": "Beate",
                    "game_user_pid": "674eebde16ae5"
                },
                {
                    "position": 99,
                    "score": 750,
                    "nickname": "Siegbert",
                    "game_user_pid": "674eebde167db"
                },
                {
                    "position": 100,
                    "score": 700,
                    "nickname": "Marco",
                    "game_user_pid": "674eebde16910"
                },
                {
                    "position": 101,
                    "score": 700,
                    "nickname": "Gertraud",
                    "game_user_pid": "674eebde169c6"
                },
                {
                    "position": 102,
                    "score": 700,
                    "nickname": "Ruth",
                    "game_user_pid": "674ee61a0cbeb"
                },
                {
                    "position": 103,
                    "score": 700,
                    "nickname": "Adele",
                    "game_user_pid": "674ee61a0c9d1"
                },
                {
                    "position": 104,
                    "score": 700,
                    "nickname": "Dominik",
                    "game_user_pid": "674ee61a0cb0a"
                },
                {
                    "position": 105,
                    "score": 700,
                    "nickname": "Lothar",
                    "game_user_pid": "674eebde169d9"
                },
                {
                    "position": 106,
                    "score": 700,
                    "nickname": "Jutta",
                    "game_user_pid": "674ee61a0cc75"
                },
                {
                    "position": 107,
                    "score": 700,
                    "nickname": "Hatice",
                    "game_user_pid": "674eebde167a8"
                },
                {
                    "position": 108,
                    "score": 700,
                    "nickname": "Hanspeter",
                    "game_user_pid": "674eebde16977"
                },
                {
                    "position": 109,
                    "score": 700,
                    "nickname": "Birgitta",
                    "game_user_pid": "674ee61a0ca5c"
                },
                {
                    "position": 110,
                    "score": 700,
                    "nickname": "Nicole",
                    "game_user_pid": "674ee61a0cb3a"
                },
                {
                    "position": 111,
                    "score": 700,
                    "nickname": "Sören",
                    "game_user_pid": "674eebde16853"
                },
                {
                    "position": 112,
                    "score": 700,
                    "nickname": "Regine",
                    "game_user_pid": "674eebde166e7"
                },
                {
                    "position": 113,
                    "score": 700,
                    "nickname": "Gebhard",
                    "game_user_pid": "674ee61a0cb4e"
                }
            ],
            "de": [
                {
                    "position": 1,
                    "score": 1850,
                    "nickname": "Sus",
                    "game_user_pid": "674ec37b01ewq"
                },
                {
                    "position": 2,
                    "score": 1800,
                    "nickname": "Susanna",
                    "game_user_pid": "674ec37b01b2c"
                },
                {
                    "position": 3,
                    "score": 1750,
                    "nickname": "Wilhelmine",
                    "game_user_pid": "674ee61a0c8c7"
                },
                {
                    "position": 4,
                    "score": 1000,
                    "nickname": "Elfi",
                    "game_user_pid": "674ee61a0c926"
                },
                {
                    "position": 5,
                    "score": 1000,
                    "nickname": "Inka",
                    "game_user_pid": "674ee61a0caf6"
                },
                {
                    "position": 6,
                    "score": 1000,
                    "nickname": "Ivan",
                    "game_user_pid": "674ee61a0c934"
                },
                {
                    "position": 7,
                    "score": 1000,
                    "nickname": "Thekla",
                    "game_user_pid": "674ee61a0cb93"
                },
                {
                    "position": 8,
                    "score": 1000,
                    "nickname": "Ramazan",
                    "game_user_pid": "674eebde16a81"
                },
                {
                    "position": 9,
                    "score": 1000,
                    "nickname": "Hanne",
                    "game_user_pid": "674ee61a0cc4d"
                },
                {
                    "position": 10,
                    "score": 1000,
                    "nickname": "Philipp",
                    "game_user_pid": "674ee61a0cb9d"
                },
                {
                    "position": 11,
                    "score": 1000,
                    "nickname": "Gundula",
                    "game_user_pid": "674ee61a0c8d5"
                },
                {
                    "position": 12,
                    "score": 1000,
                    "nickname": "Helmut",
                    "game_user_pid": "674eebde168bf"
                },
                {
                    "position": 13,
                    "score": 1000,
                    "nickname": "Ottilie",
                    "game_user_pid": "674ee61a0ca50"
                },
                {
                    "position": 14,
                    "score": 1000,
                    "nickname": "Norbert",
                    "game_user_pid": "674ee61a0cc88"
                },
                {
                    "position": 15,
                    "score": 1000,
                    "nickname": "Salvatore",
                    "game_user_pid": "674ee61a0c8fe"
                },
                {
                    "position": 16,
                    "score": 1000,
                    "nickname": "Leo",
                    "game_user_pid": "674ee61a0c966"
                },
                {
                    "position": 17,
                    "score": 1000,
                    "nickname": "Peggy",
                    "game_user_pid": "674ee61a0cbba"
                },
                {
                    "position": 18,
                    "score": 1000,
                    "nickname": "Martha",
                    "game_user_pid": "674eebde16ad0"
                },
                {
                    "position": 19,
                    "score": 1000,
                    "nickname": "August",
                    "game_user_pid": "674ee61a0c98d"
                },
                {
                    "position": 20,
                    "score": 1000,
                    "nickname": "Egbert",
                    "game_user_pid": "674ee61a0ca01"
                },
                {
                    "position": 21,
                    "score": 1000,
                    "nickname": "Fritz",
                    "game_user_pid": "674eebde16aff"
                },
                {
                    "position": 22,
                    "score": 950,
                    "nickname": "Babette",
                    "game_user_pid": "674ee61a0ca16"
                },
                {
                    "position": 23,
                    "score": 950,
                    "nickname": "Axel",
                    "game_user_pid": "674eebde168fc"
                },
                {
                    "position": 24,
                    "score": 950,
                    "nickname": "Marcel",
                    "game_user_pid": "674eebde16a64"
                },
                {
                    "position": 25,
                    "score": 950,
                    "nickname": "Wenzel",
                    "game_user_pid": "674ee61a0cbe0"
                },
                {
                    "position": 26,
                    "score": 950,
                    "nickname": "Henny",
                    "game_user_pid": "674eebde16a78"
                },
                {
                    "position": 27,
                    "score": 950,
                    "nickname": "Hugo",
                    "game_user_pid": "674eebde1676a"
                },
                {
                    "position": 28,
                    "score": 950,
                    "nickname": "Mehmet",
                    "game_user_pid": "674eebde168a2"
                },
                {
                    "position": 29,
                    "score": 950,
                    "nickname": "Thilo",
                    "game_user_pid": "674ee61a0ca47"
                },
                {
                    "position": 30,
                    "score": 950,
                    "nickname": "Mark",
                    "game_user_pid": "674ee61a0cb1d"
                },
                {
                    "position": 31,
                    "score": 950,
                    "nickname": "Detlev",
                    "game_user_pid": "674eebde16a95"
                },
                {
                    "position": 32,
                    "score": 950,
                    "nickname": "Leni",
                    "game_user_pid": "674eebde1683f"
                },
                {
                    "position": 33,
                    "score": 950,
                    "nickname": "Eugen",
                    "game_user_pid": "674eebde16a1e"
                },
                {
                    "position": 34,
                    "score": 950,
                    "nickname": "Ulrike",
                    "game_user_pid": "674ee61a0cc13"
                },
                {
                    "position": 35,
                    "score": 950,
                    "nickname": "Christian",
                    "game_user_pid": "674eebde168d3"
                },
                {
                    "position": 36,
                    "score": 950,
                    "nickname": "Melitta",
                    "game_user_pid": "674eebde1698b"
                },
                {
                    "position": 37,
                    "score": 950,
                    "nickname": "Sandy",
                    "game_user_pid": "674ee61a0c970"
                },
                {
                    "position": 38,
                    "score": 950,
                    "nickname": "Ahmet",
                    "game_user_pid": "674eebde1685d"
                },
                {
                    "position": 39,
                    "score": 950,
                    "nickname": "Klaus Peter",
                    "game_user_pid": "674ee61a0cc30"
                },
                {
                    "position": 40,
                    "score": 900,
                    "nickname": "Sylvia",
                    "game_user_pid": "674ee61a0c91c"
                },
                {
                    "position": 41,
                    "score": 900,
                    "nickname": "Ortwin",
                    "game_user_pid": "674ee61a0cb61"
                },
                {
                    "position": 42,
                    "score": 900,
                    "nickname": "Manuel",
                    "game_user_pid": "674ee61a0cb74"
                },
                {
                    "position": 43,
                    "score": 900,
                    "nickname": "Johannes",
                    "game_user_pid": "674eebde16755"
                },
                {
                    "position": 44,
                    "score": 900,
                    "nickname": "Hans",
                    "game_user_pid": "674eebde1682b"
                },
                {
                    "position": 45,
                    "score": 900,
                    "nickname": "Edmund",
                    "game_user_pid": "674eebde16884"
                },
                {
                    "position": 46,
                    "score": 900,
                    "nickname": "Peer",
                    "game_user_pid": "674ee61a0c89a"
                },
                {
                    "position": 47,
                    "score": 900,
                    "nickname": "Ulla",
                    "game_user_pid": "674ee61a0c93e"
                },
                {
                    "position": 48,
                    "score": 900,
                    "nickname": "Dorothea",
                    "game_user_pid": "674eebde16963"
                },
                {
                    "position": 49,
                    "score": 900,
                    "nickname": "Margarete",
                    "game_user_pid": "674ee61a0c9e4"
                },
                {
                    "position": 50,
                    "score": 900,
                    "nickname": "Heinz",
                    "game_user_pid": "674ee61a0cb27"
                },
                {
                    "position": 51,
                    "score": 900,
                    "nickname": "Karlheinz",
                    "game_user_pid": "674ee61a0cba6"
                },
                {
                    "position": 52,
                    "score": 900,
                    "nickname": "Wiebke",
                    "game_user_pid": "674ee61a0c9ed"
                },
                {
                    "position": 53,
                    "score": 900,
                    "nickname": "Mechthild",
                    "game_user_pid": "674ee61a0cc92"
                },
                {
                    "position": 54,
                    "score": 900,
                    "nickname": "Wladimir",
                    "game_user_pid": "674ee61a0cb44"
                },
                {
                    "position": 55,
                    "score": 900,
                    "nickname": "Jennifer",
                    "game_user_pid": "674ee61a0cbc3"
                },
                {
                    "position": 56,
                    "score": 900,
                    "nickname": "Neo22",
                    "game_user_pid": "674ec37b01b2c"
                },
                {
                    "position": 57,
                    "score": 900,
                    "nickname": "Friedrich-Wilhelm",
                    "game_user_pid": "674ee61a0c912"
                },
                {
                    "position": 58,
                    "score": 900,
                    "nickname": "Benedikt",
                    "game_user_pid": "674eebde168f3"
                },
                {
                    "position": 59,
                    "score": 900,
                    "nickname": "Adrian",
                    "game_user_pid": "674eebde16a5a"
                },
                {
                    "position": 60,
                    "score": 850,
                    "nickname": "Heinz-Georg",
                    "game_user_pid": "674eebde16741"
                },
                {
                    "position": 61,
                    "score": 850,
                    "nickname": "Wera",
                    "game_user_pid": "674ee61a0c9aa"
                },
                {
                    "position": 62,
                    "score": 850,
                    "nickname": "Klemens",
                    "game_user_pid": "674eebde169b2"
                },
                {
                    "position": 63,
                    "score": 850,
                    "nickname": "Isolde",
                    "game_user_pid": "674ee61a0ca3d"
                },
                {
                    "position": 64,
                    "score": 850,
                    "nickname": "Olga",
                    "game_user_pid": "674eebde16836"
                },
                {
                    "position": 65,
                    "score": 850,
                    "nickname": "Helen",
                    "game_user_pid": "674ee61a0c9da"
                },
                {
                    "position": 66,
                    "score": 850,
                    "nickname": "Hans-Günther",
                    "game_user_pid": "674ee61a0c908"
                },
                {
                    "position": 67,
                    "score": 850,
                    "nickname": "Ina",
                    "game_user_pid": "674eebde167d1"
                },
                {
                    "position": 68,
                    "score": 850,
                    "nickname": "Sergej",
                    "game_user_pid": "674eebde168df"
                },
                {
                    "position": 69,
                    "score": 850,
                    "nickname": "Beate",
                    "game_user_pid": "674ee61a0caa9"
                },
                {
                    "position": 70,
                    "score": 850,
                    "nickname": "Helma",
                    "game_user_pid": "674eebde16a33"
                },
                {
                    "position": 71,
                    "score": 800,
                    "nickname": "Alfons",
                    "game_user_pid": "674eebde1680e"
                },
                {
                    "position": 72,
                    "score": 800,
                    "nickname": "Harro",
                    "game_user_pid": "674eebde16867"
                },
                {
                    "position": 73,
                    "score": 800,
                    "nickname": "Eugenie",
                    "game_user_pid": "674ee61a0cc3a"
                },
                {
                    "position": 74,
                    "score": 800,
                    "nickname": "Heribert",
                    "game_user_pid": "674eebde1694b"
                },
                {
                    "position": 75,
                    "score": 800,
                    "nickname": "Mathilde",
                    "game_user_pid": "674ee61a0cbfe"
                },
                {
                    "position": 76,
                    "score": 800,
                    "nickname": "Hans-Wilhelm",
                    "game_user_pid": "674ee61a0cc09"
                },
                {
                    "position": 77,
                    "score": 800,
                    "nickname": "Rudi",
                    "game_user_pid": "674ee61a0cc7f"
                },
                {
                    "position": 78,
                    "score": 800,
                    "nickname": "Hans-Werner",
                    "game_user_pid": "674ee61a0c952"
                },
                {
                    "position": 79,
                    "score": 800,
                    "nickname": "Falko",
                    "game_user_pid": "674eebde16aa9"
                },
                {
                    "position": 80,
                    "score": 800,
                    "nickname": "Monica",
                    "game_user_pid": "674eebde16849"
                },
                {
                    "position": 81,
                    "score": 800,
                    "nickname": "Rafael",
                    "game_user_pid": "674eebde16a29"
                },
                {
                    "position": 82,
                    "score": 800,
                    "nickname": "Meinolf",
                    "game_user_pid": "674ee61a0c9f7"
                },
                {
                    "position": 83,
                    "score": 800,
                    "nickname": "Margrit",
                    "game_user_pid": "674eebde16995"
                },
                {
                    "position": 84,
                    "score": 800,
                    "nickname": "Siegmund",
                    "game_user_pid": "674ee61a0cc27"
                },
                {
                    "position": 85,
                    "score": 800,
                    "nickname": "Emanuel",
                    "game_user_pid": "674ee61a0cacf"
                },
                {
                    "position": 86,
                    "score": 800,
                    "nickname": "Friedrich-Wilhelm",
                    "game_user_pid": "674eebde1699f"
                },
                {
                    "position": 87,
                    "score": 800,
                    "nickname": "Artur",
                    "game_user_pid": "674ee61a0cbcd"
                },
                {
                    "position": 88,
                    "score": 750,
                    "nickname": "Susanne",
                    "game_user_pid": "674ee61a0caed"
                },
                {
                    "position": 89,
                    "score": 750,
                    "nickname": "Alwine",
                    "game_user_pid": "674eebde1674b"
                },
                {
                    "position": 90,
                    "score": 750,
                    "nickname": "Hans-Peter",
                    "game_user_pid": "674eebde16822"
                },
                {
                    "position": 91,
                    "score": 750,
                    "nickname": "Traudel",
                    "game_user_pid": "674ee61a0c9b3"
                },
                {
                    "position": 92,
                    "score": 750,
                    "nickname": "Hanno",
                    "game_user_pid": "674eebde16871"
                },
                {
                    "position": 93,
                    "score": 750,
                    "nickname": "Gerhard",
                    "game_user_pid": "674ee61a0ca2a"
                },
                {
                    "position": 94,
                    "score": 750,
                    "nickname": "Margret",
                    "game_user_pid": "674ee61a0cc44"
                },
                {
                    "position": 95,
                    "score": 750,
                    "nickname": "Veronika",
                    "game_user_pid": "674eebde16a14"
                },
                {
                    "position": 96,
                    "score": 750,
                    "nickname": "Rene",
                    "game_user_pid": "674eebde167c7"
                },
                {
                    "position": 97,
                    "score": 750,
                    "nickname": "Valeri",
                    "game_user_pid": "674ee61a0cc1d"
                },
                {
                    "position": 98,
                    "score": 750,
                    "nickname": "Beate",
                    "game_user_pid": "674eebde16ae5"
                },
                {
                    "position": 99,
                    "score": 750,
                    "nickname": "Siegbert",
                    "game_user_pid": "674eebde167db"
                },
                {
                    "position": 100,
                    "score": 700,
                    "nickname": "Marco",
                    "game_user_pid": "674eebde16910"
                },
                {
                    "position": 101,
                    "score": 700,
                    "nickname": "Gertraud",
                    "game_user_pid": "674eebde169c6"
                },
                {
                    "position": 102,
                    "score": 700,
                    "nickname": "Ruth",
                    "game_user_pid": "674ee61a0cbeb"
                },
                {
                    "position": 103,
                    "score": 700,
                    "nickname": "Adele",
                    "game_user_pid": "674ee61a0c9d1"
                },
                {
                    "position": 104,
                    "score": 700,
                    "nickname": "Dominik",
                    "game_user_pid": "674ee61a0cb0a"
                },
                {
                    "position": 105,
                    "score": 700,
                    "nickname": "Lothar",
                    "game_user_pid": "674eebde169d9"
                },
                {
                    "position": 106,
                    "score": 700,
                    "nickname": "Jutta",
                    "game_user_pid": "674ee61a0cc75"
                },
                {
                    "position": 107,
                    "score": 700,
                    "nickname": "Hatice",
                    "game_user_pid": "674eebde167a8"
                },
                {
                    "position": 108,
                    "score": 700,
                    "nickname": "Hanspeter",
                    "game_user_pid": "674eebde16977"
                },
                {
                    "position": 109,
                    "score": 700,
                    "nickname": "Birgitta",
                    "game_user_pid": "674ee61a0ca5c"
                },
                {
                    "position": 110,
                    "score": 700,
                    "nickname": "Nicole",
                    "game_user_pid": "674ee61a0cb3a"
                },
                {
                    "position": 111,
                    "score": 700,
                    "nickname": "Sören",
                    "game_user_pid": "674eebde16853"
                },
                {
                    "position": 112,
                    "score": 700,
                    "nickname": "Regine",
                    "game_user_pid": "674eebde166e7"
                },
                {
                    "position": 113,
                    "score": 700,
                    "nickname": "Gebhard",
                    "game_user_pid": "674ee61a0cb4e"
                }
            ]
        }
    ]
}

export const HIGHSCORES = HIGHSCORES_DATA;
