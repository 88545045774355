const GEO_DEFAULT_STATE_SUFFIX = ""
export const GEO_DEFAULT_ID = "int";

export function getGeo(geo) {
  const data = CONNECTION_GAMES_GEO.filter((el) => el.id === geo);
  return data[0]
}


let GEO_DATA;
try {
  GEO_DATA = require('../data/geo-helpers').CONNECTION_GAMES_GEO;
} catch (error) {


  GEO_DATA = [
    { "id": "int", "path": "/int" },
    { "id": "de", "path": "/de" },
    { "id": "it", "path": "/it" },
    { "id": "fr", "path": "/fr" },
  ]
}
export const CONNECTION_GAMES_GEO = GEO_DATA;




