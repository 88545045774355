import React from "react";
import { useIntl } from 'react-intl';

function CorrectPlayers() {
  const { formatMessage } = useIntl();
  
  return (
    <div className="flex flex-row gap-x-4 justify-center">
      <p className="text-base text-primary-foreground">{formatMessage({ id: 'correct_players' })}</p>
    </div>
  );
}

export default CorrectPlayers;
