import React from "react";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

function DropDown() {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger className="border-0 focus:outline-none focus:border-0">
                <div className="menu cursor-pointer"></div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
                className="bg-teal-700 text-white p-2 flex flex-col gap-3 w-36 min-w-36 mt-5"
            >
                <a href="https://www.instagram.com/create/story/" target="_blank" className="h-[24px] flex gap-2 hover:opacity-80">
                    <button className="instagram cursor-pointer"></button>
                    <p>Instagram</p>
                </a>
                <a href="https://x.com/intent/post?text=Playing%20%23Futbal11+%23FutbalConnections+-+%0A%0Ahttps%3A%2F%2Ffutbal11.com%2F" target="_blank" className="h-[24px] flex gap-2 hover:opacity-80">
                    <button className="twitter cursor-pointer"></button>
                    <p>Twitter</p>
                </a>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

export default DropDown;