import React, {useContext} from "react";
import { Outlet } from "react-router-dom"
import Header from "../Header";
import { Toaster } from "../ui/toaster";

import { GameStatusContext } from "../../providers/GameStatusProvider";
import { IntlProvider } from 'react-intl';
import { LOCALES } from '../../lib/locales';

const Layout = ({ children }) => {
  const { locale } = useContext(GameStatusContext);
  const effectiveLocale = locale || 'en';
  return (
    <div className="wrapper">
      <IntlProvider locale={effectiveLocale} messages={LOCALES[effectiveLocale]?.messages || {}}>
        <Toaster />
        <Header />
        <Outlet />
        {children}
      </IntlProvider>
    </div>
  )
}

export {
  Layout,
}
