import React, { useEffect, useContext } from "react";

import WordButton from "../WordButton";

import * as styles from "./GameGrid.module.css";

import { useSpring, animated } from "react-spring";
import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";
import { GameStatusContext } from "../../providers/GameStatusProvider";

import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

//debug mode
function isDebugMode() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('debug') === '1';
}

export const getMatchingImages = (titles, CONNECTION_GAMES) => {
  const allWordsImages = CONNECTION_GAMES.flat().flatMap(game => game.wordsImages || []);
  if (isDebugMode()) { 
    let tt = CONNECTION_GAMES.flat().map((el, index) => {  //debug add index
      return el.wordsImages.map(elem => {
        if (!/\d$/.test(elem.title)) {
          elem.title = elem.title.concat(index)
        }
        return elem;
      })
    });
    return allWordsImages.filter(wordImage => titles.includes(wordImage.title.slice(0, -1)));
  } 
  else{
    CONNECTION_GAMES.flat().map((el) => { //debug delete index
      return el.wordsImages.map(elem => {
        if (/\d$/.test(elem.title)) {
          elem.title = elem.title.slice(0, -1);
        }
        return elem;
      })
    });
  }
  return allWordsImages.filter(wordImage => titles.includes(wordImage.title));
};

function WordRow({ words, correctPlayers }) {
  const { gameData } = useContext(PuzzleDataContext);
  const newArr = getMatchingImages(words, gameData);
  return (
    <div className={`flex justify-center gap-1`}>
      {newArr.map((item) => (
        <WordButton
          key={item.title}
          word={item.title}
          image={item.image}
          fullCandidateSize={words.length}
          correctPlayers={correctPlayers}
        />
      ))}
    </div>
  );
}

export function SolvedWordRow({ ...props }) {
  const { gameData } = useContext(PuzzleDataContext);
  const arr = props.words.map(e => {
    return getMatchingImages(e, gameData);
  })
  const DIFFICULTY_COLOR_MAP = {
    1: "rgb(74 222 128)", // green
    2: "rgb(251 191 36)", // amber
    3: "rgb(129 140 248)", //indigo
    4: "rgb(34 211 238)", //cyan
  };

  const color = `${DIFFICULTY_COLOR_MAP[props.difficulty]}`;

  const springProps = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(100%)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0%)",
    },
    delay: 250,
  });
  // if there is an image available render it as a popover
  const isImageAvailable = props.imageSrc != null;
  return (
    <animated.div style={springProps}>
      {!isImageAvailable ? (
        <div style={{ backgroundColor: color, borderRadius: 8 }}>
          <p className="font-bold pt-2 pl-4">{props.category}</p>
          <p className="pb-2 pl-4">{props.words.join(", ")}</p>
        </div>
      ) : (
        <Popover>
          <PopoverTrigger asChild>
            <div
              className=" text-center cursor-pointer hover:animate-pulse shadow-md flex justify-between h-[80px]"
              style={{ backgroundColor: color, borderRadius: 8 }}
            >
              <div className="flex overflow-hidden relative min-w-[115px] w-[115px] sm:min-w-[145px] sm:w-[145px] m-1">
                <img src={arr?.flat()[0]?.image} className=" absolute bottom-0 left-[0] sm:left-[0] rounded-lg h-full" />
                <img src={arr?.flat()[1]?.image} className=" absolute bottom-0 right-[0] sm:right-[0] rounded-lg h-full" />
              </div>
              <div className="flex-grow: 1; overflow-hidden">
                <p className="font-bold pt-2 sm:pl-4">{props.category}</p>
                <p className="pb-2 sm:pl-4">{props.words.join(", ")}</p>
              </div>
              <div className="flex overflow-hidden relative min-w-[115px] w-[115px] sm:min-w-[145px] sm:w-[145px] m-1">
                <img src={arr.flat()[2]?.image} className=" absolute bottom-0 left-[0] sm:left-[0] rounded-lg h-full" />
                <img src={arr.flat()[3]?.image} className=" absolute bottom-0 right-[0] sm:right-[0] rounded-lg h-full" />
              </div>
            </div>
          </PopoverTrigger>
        </Popover>
      )}
    </animated.div>
  );
}

function GameGrid({ gameRows, shouldGridShake, setShouldGridShake, correctPlayers }) {
  const { submittedGuesses, isGameOver, isGameWon, solvedGameData } =
    useContext(GameStatusContext);
  const { gameData } = useContext(PuzzleDataContext);

  useEffect(() => {
    const shakeEffect = window.setTimeout(() => {
      setShouldGridShake(false);
      // this timeout should probably be calculated since it depends on animation values for the grid shake
    }, 2000);

    // cleanup timeout
    return () => window.clearTimeout(shakeEffect);
  }, [submittedGuesses]);
  const isGameOverAndLost = isGameOver && !isGameWon;
  const isGameOverAndWon = isGameOver && isGameWon;
  const isGameActive = !isGameOver;
  const isGameActiveWithAnySolvedRows =
    isGameActive && solvedGameData.length > 0;
  return (
    <div>
      {(isGameOverAndWon || isGameActiveWithAnySolvedRows) && (
        <div className="grid gap-y-2 pb-2">
          {solvedGameData.map((solvedRowObj) => (
            <SolvedWordRow key={solvedRowObj.category} {...solvedRowObj} />
          ))}
        </div>
      )}
      {isGameActive && (
        <div className={`grid gap-y-1 ${shouldGridShake ? styles.shake : ""}`}>
          {gameRows.map((row, idx) => (
            <WordRow key={idx} words={row} correctPlayers={correctPlayers} />
          ))}
        </div>
      )}
      {/* Show correct answers here after the game is over if they lost */}
      {isGameOverAndLost && (
        <div className="grid gap-y-2 pb-2">
          {gameData.map((obj) => (
            <SolvedWordRow key={obj.category} {...obj} />
          ))}
        </div>
      )}
    </div>
  );
}

export default GameGrid;
