import React, {useEffect, useContext, useState} from "react";
import { GameStatusContext } from "../../../providers/GameStatusProvider";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../ui/alert-dialog";

function BaseModal({
  title = "title",
  trigger = undefined,
  initiallyOpen = true,
  footerElements,
  children,
  actionButtonText = "Continue",
  showActionButton = true,
  setIsOpenProps,
  isOpenProps,
  size
}) {
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const { setisEndGameModalOpen , setisReloadGame } = useContext(GameStatusContext);

  useEffect(() => {
    setIsOpen(initiallyOpen);
    setisReloadGame(initiallyOpen);
  }, [initiallyOpen]);
  function handleCloseEvent() {
    if(setIsOpenProps) {
      setIsOpenProps()
    } else{
      setIsOpen(false);
      setisReloadGame(false);
      setisEndGameModalOpen(false);
    }
  }

  return (
    <AlertDialog open={isOpenProps ? isOpenProps : isOpen} onOpenChange={setIsOpenProps ? setIsOpenProps: setIsOpen}>
      {!!trigger && <AlertDialogTrigger>{trigger}</AlertDialogTrigger>}
      <AlertDialogContent
        handleMouseDownOnOverlay={handleCloseEvent}
        onEscapeKeyDown={handleCloseEvent}
        onCloseAutoFocus={(e) => {
          e.preventDefault();
        }}
        size={size}
      >
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <div>{children}</div>
          <AlertDialogDescription></AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {footerElements}
          {showActionButton && (
            <AlertDialogAction onClick={() =>  {
              if(setIsOpenProps) {
                setIsOpenProps(false)
              } else {
                setIsOpen(false)
              }
              }}>
              {actionButtonText}
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default BaseModal;
