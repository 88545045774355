import React, { useRef, useState, useEffect, useContext } from "react";

import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "../ui/button";
import FeedbackModal from "../modals/FeedbackModal";
import { useIntl } from 'react-intl';
import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";
import { GameStatusContext } from "../../providers/GameStatusProvider";

function Highscores() {
  const { gameHighscores, gameGeo } = useContext(PuzzleDataContext);
  const { source } = useContext(GameStatusContext);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  
  const [userPosition, setUserPosition] = useState(null);
  const itemRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const metaPid = document.querySelector('meta[name="game-user-pid"]')?.getAttribute('content');

    if (metaPid) {
      const userRecord = gameHighscores.find(record => record.game_user_pid === metaPid);
      if (userRecord) setUserPosition(userRecord.position);
    }
    setTimeout(()=>{handleScrollToItem()},300) ;
  }, []);

  const handleScrollToItem = () => {
    if (itemRef.current) itemRef.current.scrollIntoView({behavior: 'smooth',block: 'start',});
  };

  const handleNavigate = () => {
    if (source === 'game') {
      if (location.pathname.includes(gameGeo?.path)) navigate(`/game${gameGeo?.path}`);
      else navigate('/game')
    } else {
      if (location.pathname.includes(gameGeo?.path)) navigate(`${gameGeo?.path}`);
      else navigate('/')
    }
  };

  return (
    <div className="mt-6 mb-6">
      <div className="fixed right-[10px] bottom-[10px]">
        <FeedbackModal />
      </div>
      <div className={`game-wrapper border border-white rounded-xl bg-teal-700 bg-opacity-80 text-base text-white p-4`}>
        <div className="px-1.5">
          <h4 className="text-center text-4xl font-bold italic mb-2 sm:mb-4">HIGHSCORES</h4>
          {userPosition &&
            <p className="text-center cursor-pointer" onClick={handleScrollToItem}>You`re at position <span className="underline">#{userPosition}</span></p>}
          <meta name="game-user-pid" content="674ec37b01b2c"></meta>
          <div className="min-w-full grid grid-cols-3 ">
            <div className="font-semibold px-4 py-2 text-center border-b">{formatMessage({ id: 'highscores_position' })}</div>
            <div className="font-semibold px-4 py-2 text-center border-b">{formatMessage({ id: 'highscores_score' })}</div>
            <div className="font-semibold px-4 py-2 text-center border-b">{formatMessage({ id: 'highscores_name' })}</div>
          </div>
          <div className="overflow-x-auto max-h-96">
            {gameHighscores.map((item) => (
              <div
                key={item.position}
                className={`min-w-full grid grid-cols-3 ${item.position === userPosition ? 'my_position' : ''}`}
                ref={item.position === userPosition ? itemRef : null}
              >
                <div className="px-2 py-2 text-center border-b">
                  <span className="relative">
                    {item.position == 1 && <span className="absolute top-[0] left-[-20px]">🥇</span>}
                    {item.position == 2 && <span className="absolute top-[0] left-[-20px]">🥈</span>}
                    {item.position == 3 && <span className="absolute top-[0] left-[-20px]">🥉</span>}
                    {item.position}
                  </span>
                </div>
                <div className="px-1 py-2 text-center border-b">{item.score}</div>
                <div className="px-1 py-2 text-center border-b">{item.nickname}</div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-4">
            <Button onClick={handleNavigate} variant="submit" size="" className="px-0.5 text-black">{formatMessage({ id: 'highscores_back_to_game' })}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Highscores;
