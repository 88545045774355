const gameStateKey = "gameState";

export const saveGameStateToLocalStorage = (keyState,gameState) => {
  localStorage.setItem(keyState, JSON.stringify(gameState));
};

export const loadGameStateFromLocalStorage = (keyState) => {
  const state = localStorage.getItem(keyState);
  return state ? JSON.parse(state) : null;
};

const gameStatKey = "gameStats";

export const saveStatsToLocalStorage = (gameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats));
};

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey);
  return stats ? JSON.parse(stats) : null;
};
